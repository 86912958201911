import React from "react";
import {
  Box,
  Button,
  Chip,
  Typography,
  Grid,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { Navigation, Pagination, A11y } from "swiper";
import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Image from "../components/Image";
import FaceIcon from "../images/faceIcon.png";
import TeacherImage from "../images/teacher_main.png";
import One from "../images/One.svg";
import Two from "../images/Two.svg";
import Three from "../images/Three.svg";
import ADImage1 from "../images/AD/AD_Common_1.jpg";
import ADImage2 from "../images/AD/AD_Teacher_Web_1.jpg";
import ADImage3 from "../images/AD/AD_Teacher_Web_4.jpg";
import Fee from "../images/Fee.png";
import StudentManage from "../images/StudentManage.png";
import MoreDevice from "../images/MoreDevice.png";
import FeeH from "../images/FeeH.png";
import FeeW from "../images/FeeW.png";
import Warm from "../images/Warm.png";
import FaceT from "../images/FaceT.png";
import SignIn from "../images/SignIn.png";
import Profile from "../images/Profile.png";
import Paper from "../images/Paper.png";
import { useHistory } from "react-router";

const TeacherWebHome = (props: any) => {
  const isMob = useMediaQuery("(max-width: 700px)");
  const isBreak = useMediaQuery("(max-width: 1447px)");
  const isBreak_ = useMediaQuery("(max-width: 800px)");
  const history = useHistory();

  const [isTab, setTab] = React.useState("cor");
  SwiperCore.use([Navigation, Pagination, Autoplay]);

  return (
    <div>
      <Box width="100%" sx={{ bgcolor: "#2642FF" }}>
        <Box
          margin="0px auto"
          maxWidth="1447px"
          height="55vw"
          paddingY="40px"
          maxHeight="800px"
          overflow="hidden"
          position="relative"
          sx={{ bgcolor: "#2642FF" }}
        >
          <Grid
            container
            paddingLeft={isBreak ? "10vw" : "150px"}
            justifyContent="space-between"
          >
            <Box marginTop={isBreak ? "10.5vw" : "150px"} position="relative">
              <Stack direction="row" marginBottom={isBreak ? "5px" : "-10px"}>
                <Typography variant="h1" color="#FFC234">
                  티쳐스
                </Typography>
                <Typography variant="h1" color="#FFF">
                  에서는
                </Typography>
              </Stack>
              <Stack direction="row" marginBottom={isBreak ? "5px" : "-10px"}>
                <Typography variant="h1" color="#FFC234">
                  비대면 과외
                </Typography>
                <Typography variant="h1" color="#FFF">
                  를 진행해 주실
                </Typography>
              </Stack>
              <Stack direction="row" marginBottom={isBreak ? "5px" : "-10px"}>
                <Typography variant="h1" color="#FFC234">
                  선생님
                </Typography>
                <Typography variant="h1" color="#FFF">
                  을 찾고 있어요.
                </Typography>
              </Stack>
              <Typography variant="h2" textAlign="initial">
                SKY, 포항공대, 카이스트, 의예과, 치의예과에 <br /> 재학 또는
                졸업하신 선생님을 모집하고 있습니다.
              </Typography>
              <Grid container justifyContent="initial" marginTop="20px">
                <Button
                  variant="contained"
                  sx={{
                    bgcolor: "#fff",
                    color: "#2642FF",
                    fontWeight: 600,
                    fontSize: isBreak ? "1.4vw" : 18,
                    height: isBreak ? "4v" : 54,
                    borderRadius: 10,
                    zIndex: 1,
                    ":hover": {
                      bgcolor: "#b2b2b2",
                    },
                  }}
                  onClick={() => {
                    window.open("https://app.teachus.co.kr");
                    document.cookie =
                      "teacher=teacher; path=/; domain=teachus.co.kr;";
                  }}
                >
                  선생님 등록하기
                </Button>
              </Grid>
            </Box>
            <Box width="50%" paddingRight="10%" paddingTop="5%">
              <Image src={TeacherImage} width={"100%"} />
            </Box>
          </Grid>
        </Box>
      </Box>
      <Box width="100%" marginBottom="-5px">
        <Box margin="0px auto" maxWidth="1200px">
          <Swiper
            // install Swiper modules
            modules={[Navigation, Pagination, Autoplay, A11y]}
            spaceBetween={0}
            slidesPerView={1}
            autoplay={{ delay: 5000 }}
            pagination={{ clickable: true }}
            style={{ cursor: "pointer" }}
          >
            <SwiperSlide
              onClick={() => {
                window.open(
                  "https://info.teachus.co.kr/a1f42da8b21c4268850066069cbf10b6"
                );
              }}
            >
              <Image src={ADImage3} width="100%" />
            </SwiperSlide>
            <SwiperSlide
              onClick={() => {
                window.open(
                  "https://info.teachus.co.kr/5a2a52c0bd7847b7a615af2250e11601"
                );
              }}
            >
              <Image src={ADImage2} width="100%" />
            </SwiperSlide>
            <SwiperSlide>
              <Image src={ADImage1} width="100%" />
            </SwiperSlide>
          </Swiper>
        </Box>
      </Box>
      <Box width="100%" sx={{ cursor: "pointer", bgcolor: "#FFC234" }}>
        <Box
          margin="0px auto"
          maxWidth="1447px"
          height="7vw"
          maxHeight="100px"
          paddingTop={isBreak ? "1.7vw" : "22px"}
          sx={{ cursor: "pointer", bgcolor: "#FFC234" }}
          onClick={() => {
            history.push("/");
          }}
        >
          <Grid container paddingX="10%" justifyContent="space-between">
            <Box display="flex" alignItems="center" width="50%">
              <Image src={FaceIcon} width="10%" />
              <Typography
                variant="h2"
                color="white"
                fontWeight={600}
                marginLeft="10px"
              >
                학생 또는 학부모이시라면, 여기를 클릭해 주세요!
              </Typography>
            </Box>
            <Chip
              variant="outlined"
              sx={{
                color: "white",
                border: "1px solid #fff",
                cursor: "pointer",
                width: isBreak ? "17.5vw" : "237px",
                height: isBreak ? "3.7vw" : "54px",
                fontWeight: 700,
                borderRadius: "27px",
                fontSize: isBreak ? "1.4vw" : 20,
              }}
              label={"학생 페이지 방문하기"}
            />
          </Grid>
        </Box>
      </Box>
      <Box
        margin="0px auto"
        maxWidth="1447px"
        paddingY={isBreak ? "8.3vw" : "120px"}
        height="38.8vw"
        maxHeight="561px"
        position="relative"
        sx={{ bgcolor: "#fff" }}
      >
        <Typography variant="h1">현재 모집 중인 대학</Typography>
        <Typography marginTop="1%" sx={{ fontSize: isBreak ? "1.24vw" : 18 }}>
          해당 대학의 재학생 및 졸업생 선생님을 모집하고 있습니다.
        </Typography>
        <Grid container marginTop="3%" justifyContent="center">
          <Stack spacing={2}>
            <Stack direction="row" spacing={2}>
              <Chip
                sx={{
                  border: "2px solid #2642FF",
                  bgcolor: "#fff",
                  width: isBreak ? "11.1vw" : "160px",
                  height: isBreak ? "5.5vw" : "80px",
                  borderRadius: 10,
                  color: "#2642FF",
                  fontSize: isBreak ? "2.1vw" : "30px",
                  fontWeight: 600,
                }}
                label="서울대"
              />
              <Chip
                sx={{
                  border: "2px solid #2642FF",
                  bgcolor: "#fff",
                  width: isBreak ? "11.1vw" : "160px",
                  height: isBreak ? "5.5vw" : "80px",
                  borderRadius: 10,
                  color: "#2642FF",
                  fontSize: isBreak ? "2.1vw" : "30px",
                  fontWeight: 600,
                }}
                label="연세대"
              />
              <Chip
                sx={{
                  border: "2px solid #2642FF",
                  bgcolor: "#fff",
                  width: isBreak ? "11.1vw" : "160px",
                  height: isBreak ? "5.5vw" : "80px",
                  borderRadius: 10,
                  color: "#2642FF",
                  fontSize: isBreak ? "2.1vw" : "30px",
                  fontWeight: 600,
                }}
                label="고려대"
              />
              <Chip
                sx={{
                  border: "2px solid #2642FF",
                  bgcolor: "#fff",
                  width: isBreak ? "11.1vw" : "160px",
                  height: isBreak ? "5.5vw" : "80px",
                  borderRadius: 10,
                  color: "#2642FF",
                  fontSize: isBreak ? "2.1vw" : "30px",
                  fontWeight: 600,
                }}
                label="카이스트"
              />
            </Stack>
            <Stack direction="row" spacing={2}>
              <Chip
                sx={{
                  border: "2px solid #2642FF",
                  bgcolor: "#fff",
                  width: isBreak ? "11.1vw" : "160px",
                  height: isBreak ? "5.5vw" : "80px",
                  borderRadius: 10,
                  color: "#2642FF",
                  fontSize: isBreak ? "2.1vw" : "30px",
                  fontWeight: 600,
                }}
                label="포항공대"
              />
              <Chip
                sx={{
                  border: "2px solid #2642FF",
                  bgcolor: "#fff",
                  width: isBreak ? "11.1vw" : "160px",
                  height: isBreak ? "5.5vw" : "80px",
                  borderRadius: 10,
                  color: "#2642FF",
                  fontSize: isBreak ? "2.1vw" : "30px",
                  fontWeight: 600,
                }}
                label="의대"
              />
              <Chip
                sx={{
                  border: "2px solid #2642FF",
                  bgcolor: "#fff",
                  width: isBreak ? "11.1vw" : "160px",
                  height: isBreak ? "5.5vw" : "80px",
                  borderRadius: 10,
                  color: "#2642FF",
                  fontSize: isBreak ? "2.1vw" : "30px",
                  fontWeight: 600,
                }}
                label="치의대"
              />
              <Chip
                sx={{
                  border: "2px solid #2642FF",
                  bgcolor: "#fff",
                  width: isBreak ? "11.1vw" : "160px",
                  height: isBreak ? "5.5vw" : "80px",
                  borderRadius: 10,
                  color: "#2642FF",
                  fontSize: isBreak ? "2.1vw" : "30px",
                  fontWeight: 600,
                }}
                label="한의대"
              />
            </Stack>
          </Stack>
        </Grid>
      </Box>
      <Box width="100%" sx={{ bgcolor: "#F8F8F8" }}>
        <Box
          margin="0px auto"
          maxWidth="1447px"
          height="69.8vw"
          paddingY={isBreak ? "8.3%" : "120px"}
          position="relative"
          maxHeight="1010px"
          sx={{ bgcolor: "#F8F8F8" }}
        >
          <Grid container justifyContent="center">
            <Stack direction="row">
              <Typography variant="h1">이런&nbsp;</Typography>
              <Typography variant="h1" color="#2642FF">
                선생님
              </Typography>
              <Typography variant="h1">께&nbsp;</Typography>
              <Typography variant="h1" color="#2642FF">
                티쳐스를 추천
              </Typography>
              <Typography variant="h1">해요.</Typography>
            </Stack>
          </Grid>
          <Grid container justifyContent="center" marginTop="8.5%">
            <Stack direction="row" spacing={2}>
              <Box
                position="relative"
                width={isBreak ? "24.2vw" : "350px"}
                height={isBreak ? "40.8vw" : "590px"}
                borderRadius="10px"
                bgcolor="#fff"
                paddingTop="7%"
              >
                <Box width="17.7%" position="absolute" top="-5%" left="41.1%">
                  <Image src={One} width="100%" />
                </Box>
                <Button
                  variant="contained"
                  sx={{
                    position: "absolute",
                    bottom: "5%",
                    left: "4.5%",
                    bgcolor: "#2642FF",
                    color: "#fff",
                    height: isBreak ? "4vw" : 54,
                    width: isBreak ? "22vw" : 318,
                    borderRadius: 10,
                    ":hover": {
                      bgcolor: "#1b2eb2",
                    },
                  }}
                  onClick={() => {
                    window.open(
                      "https://info.teachus.co.kr/7f7dbc65ca2446718fb2b551dcc1bfae"
                    );
                  }}
                >
                  <Typography
                    sx={{ fontWeight: 600, fontSize: isBreak ? "1.4vw" : 18 }}
                  >
                    수수료 정책 자세히보기
                  </Typography>
                </Button>
                <Typography
                  fontWeight={700}
                  fontSize={isBreak ? "1.38vw" : "20px"}
                >
                  기존 비대면 과외 플랫폼의
                </Typography>
                <Grid container justifyContent="center">
                  <Stack direction="row">
                    <Typography
                      fontWeight={700}
                      fontSize={isBreak ? "1.38vw" : "20px"}
                      color="#2642FF"
                    >
                      수수료가 부담
                    </Typography>
                    <Typography
                      fontWeight={700}
                      fontSize={isBreak ? "1.38vw" : "20px"}
                    >
                      되었던 선생님
                    </Typography>
                  </Stack>
                </Grid>
                <Typography
                  marginTop="5%"
                  fontWeight={400}
                  fontSize={isBreak ? "1vw" : "15px"}
                >
                  티쳐스는 합리적인 수수료로 서비스를 제공해요
                </Typography>
                <Box position="absolute" bottom="18%">
                  <Image src={Fee} width="86.3%" />
                </Box>
              </Box>
              <Box
                position="relative"
                width={isBreak ? "24.2vw" : "350px"}
                height={isBreak ? "40.8vw" : "590px"}
                paddingTop="7%"
                borderRadius="10px"
                bgcolor="#fff"
              >
                <Button
                  variant="contained"
                  sx={{
                    position: "absolute",
                    bottom: "5%",
                    left: "4.5%",
                    bgcolor: "#2642FF",
                    color: "#fff",
                    height: isBreak ? "4vw" : 54,
                    width: isBreak ? "22vw" : 318,
                    borderRadius: 10,
                    ":hover": {
                      bgcolor: "#1b2eb2",
                    },
                  }}
                  onClick={() => {
                    window.open(
                      "https://info.teachus.co.kr/GUIDE-043de4901e0540008d98fba68318e822"
                    );
                  }}
                >
                  <Typography
                    sx={{ fontWeight: 600, fontSize: isBreak ? "1.4vw" : 18 }}
                  >
                    비대면 수업 가이드
                  </Typography>
                </Button>
                <Box width="17.7%" position="absolute" top="-5%" left="41.1%">
                  <Image src={Two} width="100%" />
                </Box>
                <Grid container justifyContent="center">
                  <Stack direction="row">
                    <Typography
                      fontWeight={700}
                      fontSize={isBreak ? "1.38vw" : "20px"}
                    >
                      수업용&nbsp;
                    </Typography>
                    <Typography
                      fontWeight={700}
                      fontSize={isBreak ? "1.38vw" : "20px"}
                      color="#2642FF"
                    >
                      기기를 추가 구매
                    </Typography>
                    <Typography
                      fontWeight={700}
                      fontSize={isBreak ? "1.38vw" : "20px"}
                    >
                      하는 것이
                    </Typography>
                  </Stack>
                </Grid>
                <Grid container justifyContent="center">
                  <Stack direction="row">
                    <Typography
                      fontWeight={700}
                      fontSize={isBreak ? "1.38vw" : "20px"}
                      color="#2642FF"
                    >
                      부담
                    </Typography>
                    <Typography
                      fontWeight={700}
                      fontSize={isBreak ? "1.38vw" : "20px"}
                    >
                      스러운 선생님
                    </Typography>
                  </Stack>
                </Grid>
                <Typography
                  marginTop="5%"
                  fontWeight={400}
                  fontSize={isBreak ? "1vw" : "15px"}
                >
                  Windows, MAC, iOS, Android 등 OS
                  <br /> 구분 없이 서비스를 지원해요.
                </Typography>
                <Box position="absolute" bottom="18%">
                  <Image src={MoreDevice} width="86.3%" />
                </Box>
              </Box>
              <Box
                position="relative"
                width={isBreak ? "24.2vw" : "350px"}
                height={isBreak ? "40.8vw" : "590px"}
                paddingTop="7%"
                borderRadius="10px"
                bgcolor="#fff"
              >
                <Button
                  variant="contained"
                  sx={{
                    position: "absolute",
                    bottom: "5%",
                    left: "4.5%",
                    bgcolor: "#2642FF",
                    color: "#fff",
                    height: isBreak ? "4vw" : 54,
                    width: isBreak ? "22vw" : 318,
                    borderRadius: 10,
                    ":hover": {
                      bgcolor: "#1b2eb2",
                    },
                  }}
                  onClick={() => {
                    window.open(
                      "https://info.teachus.co.kr/b2ffd727dcbd43c78215038ae975e421"
                    );
                  }}
                >
                  <Typography
                    sx={{ fontWeight: 600, fontSize: isBreak ? "1.4vw" : 18 }}
                  >
                    비대면 코디 알아보기
                  </Typography>
                </Button>
                <Box width="17.7%" position="absolute" top="-5%" left="41.1%">
                  <Image src={Three} width="100%" />
                </Box>
                <Grid container justifyContent="center">
                  <Stack direction="row">
                    <Typography
                      fontWeight={700}
                      fontSize={isBreak ? "1.38vw" : "20px"}
                    >
                      수업 외&nbsp;
                    </Typography>
                    <Typography
                      fontWeight={700}
                      fontSize={isBreak ? "1.38vw" : "20px"}
                      color="#2642FF"
                    >
                      학생 관리가 어렵고
                    </Typography>
                  </Stack>
                </Grid>
                <Grid container justifyContent="center">
                  <Stack direction="row">
                    <Typography
                      fontWeight={700}
                      fontSize={isBreak ? "1.38vw" : "20px"}
                      color="#2642FF"
                    >
                      부담스러운
                    </Typography>
                    <Typography
                      fontWeight={700}
                      fontSize={isBreak ? "1.38vw" : "20px"}
                    >
                      &nbsp;선생님
                    </Typography>
                  </Stack>
                </Grid>
                <Typography
                  marginTop="5%"
                  fontWeight={400}
                  fontSize={isBreak ? "1vw" : "15px"}
                >
                  티쳐스에서는 코디가 학부모상담 및<br /> 학생관리를 대신
                  진행해요.
                </Typography>
                <Box position="absolute" bottom="18%">
                  <Image src={StudentManage} width="86.3%" />
                </Box>
              </Box>
            </Stack>
          </Grid>
        </Box>
      </Box>
      <Box
        margin="0px auto"
        maxWidth="1447px"
        height="57.6vw"
        maxHeight="833px"
        paddingY={isBreak ? "8.3%" : "120px"}
        position="relative"
        sx={{ bgcolor: "#fff" }}
      >
        <Typography variant="h1">잘 가르치는 만큼, 많이 벌어가세요.</Typography>
        <Grid
          marginTop={isBreak ? "8.3vw" : "120px"}
          container
          paddingX="11.4%"
          justifyContent="space-between"
        >
          <Box width={isBreak ? "37.3vw" : "540px"}>
            <Grid container justifyContent="center">
              <Stack direction="row">
                <Typography
                  fontSize={isBreak ? "2vw" : "30px"}
                  fontWeight={700}
                >
                  과외비 시급은,&nbsp;
                </Typography>
                <Typography
                  fontSize={isBreak ? "2vw" : "30px"}
                  fontWeight={700}
                  color="#2642FF"
                >
                  직접 결정하세요!
                </Typography>
              </Stack>
            </Grid>
            <Image src={FeeH} width="100%" />
          </Box>
          <Box width={isBreak ? "37.3vw" : "540px"}>
            <Grid container justifyContent="center">
              <Stack direction="row">
                <Typography
                  fontSize={isBreak ? "2vw" : "30px"}
                  fontWeight={700}
                >
                  타사대비&nbsp;
                </Typography>
                <Typography
                  fontSize={isBreak ? "2vw" : "30px"}
                  fontWeight={700}
                  color="#2642FF"
                >
                  수수료 부담은 최소로!
                </Typography>
              </Stack>
            </Grid>
            <Image src={FeeW} width="100%" />
          </Box>
        </Grid>
      </Box>
      <Box width="100%" sx={{ bgcolor: "#2642FF" }}>
        <Box
          margin="0px auto"
          maxWidth="1447px"
          height="41.4vw"
          maxHeight="599px"
          paddingX={isBreak ? "11.4vw" : "165px"}
          paddingY={isBreak ? "8.3%" : "120px"}
          position="relative"
          sx={{ bgcolor: "#2642FF" }}
        >
          <Stack direction="row">
            <Typography color="#FFC234" variant="h1">
              비대면 과외
            </Typography>
            <Typography color="#fff" variant="h1">
              로 시간을 아끼고
            </Typography>
          </Stack>
          <Typography color="#fff" textAlign="initial" variant="h1">
            편하게 과외비 챙겨가세요
          </Typography>
          <Typography
            marginTop={isBreak ? "5.5vw" : "80px"}
            color="#fff"
            textAlign="initial"
            fontSize={isBreak ? "2vw" : "30px"}
            lineHeight={1.7}
          >
            집순이 {"&"} 집돌이 선생님도
            <br /> 본가에 거주 중인 지방러 선생님도
            <br /> 언제 어디서든 부담없이 과외하세요.
          </Typography>
          <Box
            position="absolute"
            right="11.4%"
            bottom="22.7%"
            width={isBreak ? "26.4vw" : "382px"}
          >
            <Image src={Warm} width="100%" />
          </Box>
        </Box>
      </Box>
      <Box
        margin="0px auto"
        maxWidth="1447px"
        height="58.9vw"
        paddingX={isBreak ? "11.4vw" : "165px"}
        paddingY={isBreak ? "8.3%" : "120px"}
        maxHeight="852px"
        position="relative"
        sx={{ bgcolor: "#fff" }}
      >
        <Stack direction="row">
          <Typography variant="h1">티쳐스 선생님&nbsp; </Typography>
          <Image src={FaceT} width={isBreak ? "3.7vw" : "54px"} />
          <Typography variant="h1">&nbsp;되는 방법 !</Typography>
        </Stack>
        <Box height={isBreak ? "8.3vw" : "120px"} />
        <Grid container justifyContent="space-between">
          <Box width={isBreak ? "19.7vw" : "285px"}>
            <Typography fontSize={isBreak ? "1.38vw" : "20px"} fontWeight={700}>
              STEP 1
            </Typography>
            <Grid container justifyContent="center">
              <Stack direction="row">
                <Typography
                  fontSize={isBreak ? "2vw" : "30px"}
                  fontWeight={700}
                >
                  강사로&nbsp;
                </Typography>
                <Typography
                  fontSize={isBreak ? "2vw" : "30px"}
                  fontWeight={700}
                  color="#2642FF"
                >
                  회원가입
                </Typography>
              </Stack>
            </Grid>
            <Box height="8px" />
            <Image src={SignIn} width="100%" />
          </Box>
          <Box width={isBreak ? "19.7vw" : "285px"}>
            <Typography fontSize={isBreak ? "1.38vw" : "20px"} fontWeight={700}>
              STEP 2
            </Typography>
            <Grid container justifyContent="center">
              <Stack direction="row">
                <Typography
                  fontSize={isBreak ? "2vw" : "30px"}
                  color="#2642FF"
                  fontWeight={700}
                >
                  프로필&nbsp;
                </Typography>
                <Typography
                  fontSize={isBreak ? "2vw" : "30px"}
                  fontWeight={700}
                >
                  등록
                </Typography>
              </Stack>
            </Grid>
            <Box height="8px" />
            <Image src={Profile} width="100%" />
          </Box>
          <Box width={isBreak ? "19.7vw" : "285px"}>
            <Typography fontSize={isBreak ? "1.38vw" : "20px"} fontWeight={700}>
              STEP 3
            </Typography>
            <Grid container justifyContent="center">
              <Stack direction="row">
                <Typography
                  fontSize={isBreak ? "2vw" : "30px"}
                  color="#2642FF"
                  fontWeight={700}
                >
                  필수 서류&nbsp;
                </Typography>
                <Typography
                  fontSize={isBreak ? "2vw" : "30px"}
                  fontWeight={700}
                >
                  제출
                </Typography>
              </Stack>
            </Grid>
            <Box height="8px" />
            <Image src={Paper} width="100%" />
          </Box>
        </Grid>
        <Box height={isBreak ? "2.76vw" : "40px"} />
        <Box
          width="100%"
          height={isBreak ? "6.8vw" : "98px"}
          bgcolor="#F8F8F8"
          padding={isBreak ? "1.9vw 2.8vw" : "28px 40px"}
        >
          <Stack direction="row" spacing={4} alignItems="center">
            <Typography
              fontSize={isBreak ? "2vw" : "30px"}
              color="#2642FF"
              fontWeight={700}
            >
              TIP.
            </Typography>
            <Typography fontSize={isBreak ? "1.38vw" : "20px"}>
              프로필을 잘 작성해주실 수록, 과외 성사율이 높아집니다.
            </Typography>
          </Stack>
        </Box>
        <Button
          variant="contained"
          sx={{
            position: "absolute",
            top: "15.6%",
            right: "11.4%",
            bgcolor: "#2642FF",
            color: "#fff",
            fontWeight: 600,
            fontSize: isBreak ? "1.4vw" : 18,
            height: isBreak ? "4vw" : 54,
            borderRadius: 10,
            ":hover": {
              bgcolor: "#1b2eb2",
            },
          }}
          onClick={() => {
            window.open("https://app.teachus.co.kr");
            document.cookie = "teacher=teacher; path=/; domain=teachus.co.kr;";
          }}
        >
          지금 등록하기
        </Button>
      </Box>
    </div>
  );
};

export default TeacherWebHome;
