import { Avatar, AvatarProps } from "@mui/material";
import styled from "styled-components";

const StyledAvatar = styled(Avatar)`
  width: ${(props: Props) => (props.size ? props.size : "100px")};
  height: ${(props: Props) => (props.size ? props.size : "100px")};
`;

interface Props {
  size?: string;
}

const TUAvatar = ({ size = "100px", ...rest }: AvatarProps & Props) => {
  return <StyledAvatar {...rest} size={size} />;
};

export default TUAvatar;
