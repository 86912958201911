import { createTheme } from "@mui/material/styles";
//import { colors } from "@material-ui/core";
import "./index.css";
import "./App.css";

const contentBold = {
  // fontFamily: 'AppleSDGothic',
  fontStyle: "bold",
  fontWeight: "bold",
  // src: `
  //   url(${BoldFontTTF}) format('ttf'),
  //   url(${BoldFontEOT}) format('eot'),
  //   url(${BoldFontWOFF}) format('woff'),
  // `,
};

// Create a theme instance.
const theme = createTheme({
  overrides: {
    MuiButtonGroup: {
      root: {
        borderRadius: "10px",
      },
    },
    MuiBottomNavigation: {
      root: {
        boxShadow: "0px -2px 5px rgba(196, 196, 196, 0.2)",
      },
    },
    MuiBottomNavigationAction: {
      label: {
        fontSize: "10px !important",
        fontWeight: 600,
        marginTop: "4px",
      },
    },
    MuiButton: {
      contained: {
        backgroundColor: "#c4c4c4",
        color: "white",
      },
      root: {
        height: "50px",
        fontSize: "14px",
        fontWeight: 700,
        borderRadius: "10px",
        boxShadow: "none !important",
      },
      sizeSmall: {
        borderRadius: "10px",
        height: "40px",
      },
      sizeLarge: {
        height: "120px",
        fontSize: "22px",
        borderRadius: 0,
      },

      outlinedSizeLarge: {
        height: "50px",
        color: "#767676",
        fontWeight: 700,
        borderColor: "#767676",
        borderWidth: "0.7px",
      },
    },
    MuiListItemText: {
      inset: {
        padding: "0 !important",
      },
    },
    MuiImageListItem: {
      root: {
        height: "auto !important",
      },
    },
    MuiChip: {
      root: {
        height: "40px",
        fontSize: "14px",
        fontWeight: 600,
        boxShadow: "none !important",
        borderRadius: "20px",
        fontWeight: 600,
      },
      sizeSmall: {
        height: "34px",
        borderRadius: "17px",
        padding: "8px 10px",
      },
      labelSmall: {
        fontSize: "14px",
        fontWeight: 600,
      },
      outlined: {
        height: "34px",
        borderRadius: "17px",
        padding: "8px 10px",
      },
    },
    MuiSwitch: {
      root: {
        width: 48,
        height: 26,
        padding: 0,
        // margin: theme.spacing(1),
      },
      switchBase: {
        padding: 1,
        "&$checked": {
          transform: "translateX(22px) !important",
          // color: '#fff !important',
          "& + $track": {
            // backgroundColor: '#fff',
            // opacity: '1 !important',
            border: "none",
          },
        },
        "&$focusVisible $thumb": {
          thumb: {
            // color: '#fff',
            // border: '6px solid #fff important',
          },
        },
      },
      thumb: {
        width: 24,
        height: 24,
        color: "#fff",
        border: "6px solid #fff important",
      },
      track: {
        borderRadius: 26 / 2,
        backgroundColor: "#cbcbcb",
        opacity: "1 !important",
        // transition: theme.transitions.create(['background-color', 'border']),
      },
      checked: {},
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: "10px",
        borderColor: "#767676",
        minHeight: "40px",
      },
      multiline: {
        padding: "4px 2px",
      },
    },
    MuiDialog: {
      paper: {
        background: "rgba(255, 255, 255, 0.8)",
        boxShadow: "0px 0px 12px #CAD0FF",
        borderRadius: "10px",
      },
    },
    MuiTab: {
      textColorPrimary: {
        "&$selected": {
          color: "#000 !important",
        },
      },
      wrapper: {
        fontWeight: 600,
        fontSize: "16px",
        color: "inherit",
      },
    },
  },
  palette: {
    primary: {
      light: "#CAD0FF",
      main: "#94A5FD",
      contrastText: "#fff",
    },
    secondary: {
      main: "#99d5ca",
    },
    text: {
      primary: "#000000",
      secondary: "#767676",
    },
    error: {
      main: "#FF0000",
    },
    success: {
      main: "#008625",
    },
  },
  typography: {
    fontFamily: "Noto Sans CJK KR",
    fontWeight: 300,
    // fontFaces: [regular],
    body1: {
      fontSize: "14px",
      lineHeight: 1.5,
    },
    body2: {
      fontSize: "12px",
    },
    subtitle1: {
      fontSize: "22px",
      fontWeight: 500,
      "@media (max-width: 700px)": {
        fontSize: "18px",
        fontWeight: 500,
      },
      "@media (max-width: 350px)": {
        fontSize: "16px",
        fontWeight: 500,
      },
    },
    h1: {
      fontFamily: "GmarketB",
      fontSize: "48px",
      fontWeight: 1500,
      lineHeight: 1.5,
      "@media (max-width: 1447px)": {
        fontSize: "3.3vw",
        lineHeight: 1.2,
      },
      "@media (max-width: 700px)": {
        fontSize: "7.3vw",
        lineHeight: 1.2,
      },
    },
    h2: {
      color: "#fff",
      fontSize: 20,
      fontWeight: "normal",
      "@media (max-width: 1447px)": {
        fontSize: "1.4vw",
        lineHeight: 1.2,
      },
      "@media (max-width: 700px)": {
        fontSize: "4.2vw",
        lineHeight: 1.3,
      },
    },
    h3: {
      fontFamily: "GmarketB",
      fontSize: "36px",
      fontWeight: 1500,
      lineHeight: 1.6,
      "@media (max-width: 1447px)": {
        fontSize: "2.8vw",
        lineHeight: 1.3,
      },
    },
    h4: {
      fontSize: "22px",
      fontWeight: 600,
      lineHeight: 1,
      "@media (max-width: 1447px)": {
        fontSize: "calc(12px + 0.6vw);",
        lineHeight: 1.3,
      },
    },
    h5: {
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: 1.5,
      "@media (max-width: 1447px)": {
        fontSize: "calc(10px + 0.2vw);",
        lineHeight: 1.3,
      },
    },
    h6: {
      fontSize: "14px",
      lineHeight: 1.5,
      "@media (max-width: 1447px)": {
        fontSize: "calc(10px + 0.1vw);",
        lineHeight: 1.3,
      },
    },
  },
  // typography: {
  //   // fontFamily: [regular.fontFamily, contentBold.fontFamily].join(','),
  //   // fontFaces: [regular, contentBold],
  //   h1: {
  //     fontWeight: '900',
  //     lineHeight: 1.735,
  //     // fontFamily: 'AppleSDGothic',
  //   },
  //   h2: {
  //     fontWeight: '900',
  //     lineHeight: 1.735,
  //     // fontFamily: 'AppleSDGothic',
  //   },
  //   h3: {
  //     fontWeight: '900',
  //     lineHeight: 1.735,
  //     // fontFamily: 'AppleSDGothic',
  //   },
  //   h4: {
  //     fontWeight: '900',
  //     lineHeight: 1.5,
  //     // fontFamily: 'AppleSDGothic',
  //   },
  //   h5: {
  //     fontWeight: '900',
  //     lineHeight: 1.5,
  //     // fontFamily: 'AppleSDGothic',
  //   },
  //   h6: {
  //     fontWeight: '900',
  //     // fontFamily: 'AppleSDGothic',
  //   },
  //   body1: {
  //     fontWeight: 'normal',
  //     fontSize: '1rem',
  //   },
  //   body2: {
  //     fontWeight: '100',
  //   },
  //   subtitle1: {
  //     fontWeight: '100',
  //     fontSize: '1.1rem',
  //   },
  //   subtitle2: {
  //     fontWeight: '100',
  //     fontSize: '0.8rem',
  //   },
  //   caption: {
  //     fontWeight: '100',
  //     color: colors.grey['500'],
  //   },
  //   background: {
  //     default: '#fff',
  //   },
  // },
});

export default theme;
