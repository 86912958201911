import React from "react";
import {
  Box,
  Button,
  Chip,
  Typography,
  Grid,
  Paper,
  Stack,
  useMediaQuery,
} from "@mui/material";
import {
  ListTeachersQuery,
  FeeInfo,
  GetFeeInfoQuery,
  TeacherWithImage,
} from "../graphql";
import axios from "axios";
import { Navigation, Pagination, A11y } from "swiper";
import TUAvatar from "../components/TUAvatar";
import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Image from "../components/Image";
import ADImage1 from "../images/AD/AD_Student_Web_1.png";
import ADImage2 from "../images/AD/AD_Student_Web_2.png";
import ADImage3 from "../images/AD/AD_Student_Web_3.png";
import ADImage4 from "../images/AD/AD_Common_1.jpg";
import ADImage5 from "../images/AD/AD_Common.png";
import ADImage6 from "../images/AD/AD_Student_Web.jpg";
import ADImage7 from "../images/AD/AD_Student_Web_1.jpg";
import ADImage8 from "../images/AD/AD_Student_Web_2.jpg";
import ADImage9 from "../images/AD/AD_Student_Web_3.jpg";
import VectorI from "../images/St_webVector.png";
import Small from "../images/Small.png";
import FeedBackImage from "../images/FeedBack.png";
import FaceIcon from "../images/faceIcon.png";
import ThumbIcon from "../images/Thumb.png";
import smallFaceIcon from "../images/Face.png";
import HandIcon from "../images/Hand.png";
import S_FaceIcon from "../images/s_FaceIcon.png";
import K_FaceIcon from "../images/k_FaceIcon.png";
import Y_FaceIcon from "../images/y_FaceIcon.png";
import CommuIcon from "../images/Communication.png";
import RemindIcon from "../images/Remind.png";
import DeviceIcon from "../images/Device.png";
import CordiImage from "../images/Cordi.png";
import TabletImage from "../images/Tablet.png";
import PriceImage from "../images/Price.png";
import Left from "../images/Left.svg";
import Right from "../images/Right.svg";
import { useHistory } from "react-router";

const StudentWebHome = (props: any) => {
  const isBreak = useMediaQuery("(max-width: 1447px)");
  const isMob = useMediaQuery("(max-width: 800px)");
  const isMob_ = useMediaQuery("(max-width: 950px)");
  const history = useHistory();

  const [isTab, setTab] = React.useState("cor");

  const [feeInfo, setFeeInfo] = React.useState<FeeInfo>();
  const [stuList, setStuList] = React.useState<
    (TeacherWithImage | null | undefined)[] | undefined | null
  >(undefined);
  const [subjects, setSubjects] = React.useState<string[]>([]);

  React.useEffect(() => {
    getFeeInfoFunction();
    getTeachersList();
  }, []);
  async function getFeeInfoFunction() {
    try {
      const apiData = (
        await axios.get(
          "https://4hyrv53rv0.execute-api.ap-northeast-2.amazonaws.com/dev/getFeeInfo-dev"
        )
      ).data as { data: GetFeeInfoQuery };

      if (apiData && apiData.data.getFeeInfo) {
        setFeeInfo(apiData.data.getFeeInfo);
        return;
      } else {
        return;
      }
    } catch (e) {
      //console.log(e);
    }
  }

  // 제안 가능한 강사들
  async function getTeachersList() {
    const { data } = await axios.post(
      "https://0ah3gq55m9.execute-api.ap-northeast-2.amazonaws.com/dev/items",
      { subject: subjects }
    );
    const apiData = {
      data: {
        listTeachers: {
          __typename: "ModelTeacherConnection",
          items: data,
          nextToken: null,
        },
      },
    } as { data: ListTeachersQuery };

    try {
      if (apiData?.data?.listTeachers) {
        setStuList(
          apiData.data.listTeachers.items?.map((teacher) => {
            return teacher && teacher.profileImage
              ? { ...teacher, imageURL: teacher.profileImage }
              : teacher;
          })
        );
      }
      return;
    } catch (e) {
      //console.log(e);
    }
  }

  return (
    <div style={{ overflow: "hidden" }}>
      <Box width="100%" sx={{ bgcolor: "#2642FF" }}>
        <Box
          margin="0px auto"
          maxWidth="1447px"
          height="55vw"
          paddingY="40px"
          maxHeight="800px"
          position="relative"
          sx={{ bgcolor: "#2642FF" }}
        >
          <Grid
            container
            paddingLeft={isBreak ? "10vw" : "150px"}
            justifyContent="space-between"
          >
            <Box marginTop={isBreak ? "10.5vw" : "150px"} position="relative">
              <Box position="absolute" top="-18px" left="-20px">
                <Image src={Small} width="20px" />
              </Box>
              <Typography textAlign="initial" variant="h2" marginBottom="15px">
                100% SKY 선생님은 기본!
              </Typography>
              <Stack direction="row" marginBottom={isBreak ? "5px" : "-10px"}>
                <Typography variant="h1" color="#FFC234">
                  비대면 과외
                </Typography>
                <Typography variant="h1" color="#FFF">
                  를 위한
                </Typography>
              </Stack>
              <Stack direction="row" marginBottom={isBreak ? "10px" : "0px"}>
                <Typography variant="h1" color="#fff">
                  최고의 선택,
                </Typography>
                <Typography variant="h1" color="#FFC234">
                  &nbsp;티쳐스
                </Typography>
              </Stack>
              <Typography variant="h2" textAlign="initial">
                수업부터 학습관리까지
              </Typography>
              <Stack direction="row">
                <Typography
                  variant="h2"
                  textAlign="initial"
                  sx={{ fontWeight: 600 }}
                >
                  강사+코디&nbsp;
                </Typography>
                <Typography variant="h2" textAlign="initial">
                  듀얼케어 시스템으로 완벽하게 !
                </Typography>
              </Stack>
              <Grid container justifyContent="initial" marginTop="20px">
                <Button
                  variant="contained"
                  sx={{
                    bgcolor: "#FFC234",
                    color: "#2642FF",
                    fontWeight: 600,
                    fontSize: isBreak ? "1.4vw" : 18,
                    height: isBreak ? "4v" : 54,
                    width: isBreak ? "12vw" : 165,
                    borderRadius: 10,
                    zIndex: 1,
                    ":hover": {
                      bgcolor: "#ce9c2a",
                    },
                  }}
                  onClick={() => {
                    window.open("https://app.teachus.co.kr");
                    document.cookie =
                      "teacher=student; path=/; domain=teachus.co.kr;";
                  }}
                >
                  시작하기
                </Button>
              </Grid>
            </Box>
            <Box marginRight={isBreak ? "-12vw" : "-180px"} marginLeft="-600px">
              {/* <Image src={MainImage} width={isBreak ? "81vw" : "1177px"} /> */}
            </Box>
          </Grid>
          <Box
            position="absolute"
            bottom={isBreak ? "1.17vw" : "17px"}
            right={isBreak ? "-12.3vw" : "-178px"}
          >
            <Image src={TabletImage} width={isBreak ? "81.6vw" : "1177px"} />
          </Box>
          <Box
            position="absolute"
            bottom={isBreak ? "11.7vw" : "169px"}
            right={isBreak ? "0.8vw" : "11px"}
          >
            <Image src={VectorI} width={isBreak ? "58.8vw" : "851px"} />
          </Box>
        </Box>
      </Box>
      <Box
        margin="0px auto"
        marginBottom="-5px"
        maxWidth="1200px"
        paddingY="auto"
        maxHeight="455px"
        sx={{ bgcolor: "#fff" }}
      >
        <Swiper
          // install Swiper modules
          modules={[Navigation, Pagination, Autoplay, A11y]}
          spaceBetween={0}
          slidesPerView={1}
          autoplay={{ delay: 5000 }}
          pagination={{ clickable: true }}
          style={{ cursor: "pointer" }}
        >
          {/* <SwiperSlide
            onClick={() => {
              window.open(
                "https://prosedu.notion.site/50-ab4d504fb3794c639a0b956de87df3c1"
              );
            }}
          >
            <Image src={ADImage2} width="100%" />
          </SwiperSlide> */}
          <SwiperSlide
            onClick={() => {
              window.open(
                "https://info.teachus.co.kr/c8e35c25359544b9bc6372c64a103847"
              );
            }}
          >
            <Image src={ADImage3} width="100%" />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => {
              window.open(
                "https://info.teachus.co.kr/3a4a945024a9453880e2043e903e1ad7"
              );
            }}
          >
            <Image src={ADImage1} width="100%" />
          </SwiperSlide>
          <SwiperSlide>
            <Image src={ADImage7} width="100%" />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => {
              window.open(
                "https://info.teachus.co.kr/98e80b3594434c59be1c8984259992b5"
              );
            }}
          >
            <Image src={ADImage6} width="100%" />
          </SwiperSlide>
          <SwiperSlide>
            <Image src={ADImage4} width="100%" />
          </SwiperSlide>
          <SwiperSlide>
            <Image src={ADImage8} width="100%" />
          </SwiperSlide>
          <SwiperSlide>
            <Image src={ADImage9} width="100%" />
          </SwiperSlide>
        </Swiper>
      </Box>
      <Box width="100%" sx={{ cursor: "pointer", bgcolor: "#FFC234" }}>
        <Box
          margin="0px auto"
          maxWidth="1447px"
          height="6.9vw"
          maxHeight="100px"
          paddingTop={isBreak ? "1.7vw" : "22px"}
          sx={{ cursor: "pointer", bgcolor: "#FFC234" }}
          onClick={() => {
            history.push("/teacher");
          }}
        >
          <Grid container paddingX="11.4%" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <Image src={FaceIcon} width="10%" />
              <Typography
                variant="h2"
                color="white"
                fontWeight={600}
                marginLeft="10px"
              >
                선생님이시라면, 여기를 클릭해 주세요!
              </Typography>
            </Box>
            <Chip
              variant="outlined"
              sx={{
                color: "white",
                border: "1px solid #fff",
                cursor: "pointer",
                height: isBreak ? "3.7vw" : "54px",
                fontWeight: 700,
                borderRadius: "27px",
                paddingX: "2%",
                fontSize: isBreak ? "1.4vw" : 20,
              }}
              label={"선생님 페이지 방문하기"}
            />
          </Grid>
        </Box>
      </Box>
      <Box width="100%" sx={{ bgcolor: "#F8F8F8" }}>
        <Box
          margin="0px auto"
          maxWidth="1447px"
          height="53vw"
          paddingTop={isBreak ? "8.2vw" : "119px"}
          paddingX={isBreak ? "5.8vw" : "85px"}
          maxHeight="769px"
          position="relative"
          sx={{ bgcolor: "#F8F8F8" }}
        >
          <Grid
            container
            justifyContent="space-between"
            alignItems="flex-end"
            paddingX={isBreak ? "5.6vw" : "80px"}
          >
            <Box position="relative">
              <Box position="absolute" top="-18px" left="-20px">
                <Image src={Small} width="20px" />
              </Box>
              <Typography
                variant="h1"
                textAlign="initial"
                sx={{ lineHeight: "60px" }}
              >
                명문대 출신
              </Typography>
              <Stack direction="row" marginBottom={isBreak ? "10px" : "10px"}>
                <Typography
                  variant="h1"
                  color="#2642FF"
                  sx={{ lineHeight: "60px" }}
                >
                  최고의 선생님
                </Typography>
                <Typography variant="h1" sx={{ lineHeight: "60px" }}>
                  과 함께하세요.
                </Typography>
              </Stack>
              <Typography variant="h2" textAlign="initial" color="#000000">
                <b>1:1 맞춤형 수업</b>을 통해 학생 성적과 학습 스타일에 딱 맞는
                수업을 경험하세요.
                <br />
                티쳐스에서는 <b>100% SKY, 의치대 이상</b>의 국내 최고의 강사
                300여명이 여러분들을 기다립니다.
                <br /> 자체적인 검증을 통한 우수강사 추천 시스템으로 마음에 드는
                선생님을 직접 골라보세요. <br />{" "}
                <b>무제한 30분 무료 시범 강의</b>를 통해 학생과 딱 맞는 선생님을
                만날 수 있습니다.
              </Typography>
            </Box>
            <Button
              variant="contained"
              sx={{
                // position: "absolute",
                // top: isBreak ? "10vw" : 140,
                // right: isBreak ? "12vw" : 170,
                paddingX: isBreak ? "2.5vw" : "36px",
                bgcolor: "#2642FF",
                color: "#fff",
                fontWeight: 600,
                fontSize: isBreak ? "1.4vw" : 18,
                height: isBreak ? "4vw" : 54,
                borderRadius: 10,
                ":hover": {
                  bgcolor: "#1b2eb2",
                },
              }}
              onClick={() => {
                window.open("https://app.teachus.co.kr");
                document.cookie =
                  "teacher=search; path=/; max-age=5; domain=teachus.co.kr;";
              }}
            >
              선생님 찾아보기
            </Button>
          </Grid>
          <Box position="relative">
            <div
              className="navigation-nextt"
              style={{
                position: "absolute",
                top: "40%",
                right: "0",
                width: "12px",
                height: "15px",
              }}
            >
              <Image src={Right} width="12px" />
            </div>
            <div
              className="navigation-prevv"
              style={{
                position: "absolute",
                top: "40%",
                width: "12px",
                height: "15px",
              }}
            >
              <Image src={Left} width="12px" />
            </div>
            <Swiper
              style={{
                marginTop: "5%",
                marginLeft: isBreak ? "5.6vw" : "80px",
                marginRight: isBreak ? "5.6vw" : "80px",
              }}
              spaceBetween={isMob ? 15 : 30}
              slidesPerView={4}
              modules={[Navigation, A11y]}
              navigation={{
                nextEl: ".navigation-nextt",
                prevEl: ".navigation-prevv",
              }}
            >
              {stuList
                ?.filter((i) => i?.recruitment === true)
                ?.filter((i) => i?.excellent === true)
                .slice(0, 7)
                .map((student) => {
                  return (
                    <SwiperSlide
                      style={{
                        width: isBreak ? "17.6vw" : "255px",
                        height: isBreak ? "21.7vw" : "315px",
                        borderRadius: 10,
                        wordBreak: "normal",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        window.open("https://app.teachus.co.kr");
                        document.cookie =
                          "teacher=search; path=/; max-age=5; domain=teachus.co.kr;";
                      }}
                    >
                      <Box
                        borderRadius="10px"
                        bgcolor="#fff"
                        paddingY={isBreak ? "2vw" : "38px"}
                        paddingX={isBreak ? "1vw" : "24px"}
                        height="90%"
                      >
                        <Grid container justifyContent="center">
                          <TUAvatar
                            src={student?.imageURL}
                            size={!isBreak ? "85px" : "5.8vw"}
                          />
                        </Grid>
                        <Typography
                          sx={{
                            fontSize: isBreak ? "1.24vw" : 18,
                            fontWeight: 700,
                          }}
                        >
                          {student?.name}쌤
                        </Typography>
                        <Chip
                          variant="filled"
                          sx={{
                            bgcolor: student?.subjects
                              ? [0]
                                ? student.subjects[0] === "국어"
                                  ? "#D34F65"
                                  : student.subjects[0] === "수학"
                                  ? "#FFC234"
                                  : "#2642FF"
                                : "#D34F65"
                              : "#D34F65",
                            color: "#fff",
                            height: isBreak ? "1.5vw" : 20,
                            fontSize: isBreak ? "0.9vw" : 12,
                          }}
                          label={
                            student?.subjects
                              ? [0]
                                ? student?.subjects[0]
                                : ""
                              : ""
                          }
                        />
                        <Typography marginTop="10%" variant="h6">
                          {student?.university}&nbsp;
                          {student?.major}
                        </Typography>
                        <Typography marginTop="5%" variant="h6" color="#4B57DD">
                          {isMob_
                            ? student?.introductionTitle?.slice(0, 20)
                            : student?.introductionTitle?.slice(0, 30)}
                          {student?.introductionTitle
                            ? isMob_
                              ? student.introductionTitle.length > 30 && "..."
                              : student.introductionTitle.length > 20 && "..."
                            : null}
                        </Typography>
                      </Box>
                    </SwiperSlide>
                  );
                })}
              {stuList
                ?.filter((i) => i?.recruitment === true)
                ?.filter((i) => i?.excellent !== true)
                .slice(0, 9)
                .map((student) => {
                  return (
                    <SwiperSlide
                      style={{
                        width: isBreak ? "17.6vw" : "255px",
                        height: isBreak ? "21.7vw" : "315px",
                        borderRadius: 10,
                        wordBreak: "normal",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        window.open("https://app.teachus.co.kr");
                        document.cookie =
                          "teacher=search; path=/; max-age=5; domain=teachus.co.kr;";
                      }}
                    >
                      <Box
                        borderRadius="10px"
                        bgcolor="#fff"
                        paddingY={isBreak ? "2vw" : "38px"}
                        paddingX={isBreak ? "1vw" : "24px"}
                        height="90%"
                      >
                        <Grid container justifyContent="center">
                          <TUAvatar
                            src={student?.imageURL}
                            size={!isBreak ? "85px" : "5.8vw"}
                          />
                        </Grid>
                        <Typography
                          sx={{
                            fontSize: isBreak ? "1.24vw" : 18,
                            fontWeight: 700,
                          }}
                        >
                          {student?.name}쌤
                        </Typography>
                        <Chip
                          variant="filled"
                          sx={{
                            bgcolor: student?.subjects
                              ? [0]
                                ? student.subjects[0] === "국어"
                                  ? "#D34F65"
                                  : student.subjects[0] === "수학"
                                  ? "#FFC234"
                                  : "#2642FF"
                                : "#D34F65"
                              : "#D34F65",
                            color: "#fff",
                            height: isBreak ? "1.5vw" : 20,
                            fontSize: isBreak ? "0.9vw" : 12,
                          }}
                          label={
                            student?.subjects
                              ? [0]
                                ? student?.subjects[0]
                                : ""
                              : ""
                          }
                        />
                        <Typography marginTop="10%" variant="h6">
                          {student?.university}&nbsp;
                          {student?.major}
                        </Typography>
                        <Typography marginTop="5%" variant="h6" color="#4B57DD">
                          {isMob_
                            ? student?.introductionTitle?.slice(0, 20)
                            : student?.introductionTitle?.slice(0, 30)}
                          {student?.introductionTitle
                            ? isMob_
                              ? student.introductionTitle.length > 30 && "..."
                              : student.introductionTitle.length > 20 && "..."
                            : null}
                        </Typography>
                      </Box>
                    </SwiperSlide>
                  );
                })}
              <SwiperSlide
                style={{
                  width: isBreak ? "17.6vw" : "255px",
                  height: isBreak ? "21.7vw" : "315px",
                  borderRadius: 10,
                  cursor: "pointer",
                }}
                onClick={() => {
                  window.open("https://app.teachus.co.kr");
                  document.cookie =
                    "teacher=search; path=/; max-age=5; domain=teachus.co.kr;";
                }}
              >
                <Box
                  borderRadius="10px"
                  bgcolor="#fff"
                  paddingY={isBreak ? "2vw" : "38px"}
                  paddingX={isBreak ? "1vw" : "24px"}
                  height="85%"
                >
                  <Grid container justifyContent="center" marginTop="5%">
                    <Box>
                      <Typography
                        sx={{
                          fontSize: isBreak ? "3.72vw" : 54,
                          fontWeight: 700,
                        }}
                      >
                        +
                      </Typography>
                    </Box>
                  </Grid>
                  <Typography
                    marginTop="25%"
                    sx={{
                      fontSize: isBreak ? "1.2vw" : 18,
                      fontWeight: 600,
                    }}
                  >
                    더 많은 선생님 보기
                  </Typography>
                </Box>
              </SwiperSlide>
            </Swiper>
          </Box>
        </Box>
      </Box>
      <Box width="100%">
        <Box margin="0px auto" maxWidth="1447px" sx={{ bgcolor: "#fff" }}>
          <Grid container justifyContent="center">
            <Stack
              direction="row"
              marginTop={isBreak ? "7vw" : "95px"}
              position="relative"
            >
              <Typography variant="h1" color="#000">
                티쳐스는&nbsp;
              </Typography>
              <Typography variant="h1" color="#2642FF">
                수업 후 집중관리
              </Typography>
              <Typography variant="h1" color="#000">
                에 중점을 두었습니다.
              </Typography>
              <Box position="absolute" top="-8px" left="-10px">
                <Image src={Small} width="15px" />
              </Box>
            </Stack>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            paddingX="10%"
            paddingTop="5%"
          >
            <Box
              width="48%"
              height={isBreak ? "6vw" : "80px"}
              sx={{
                boxShadow:
                  isTab === "cor" ? "none" : "0px -5px 10px rgba(0, 0, 0, 0.1)",
                bgcolor: isTab === "cor" ? "#2642FF" : "#fff",
                borderTopRightRadius: 15,
                borderTopLeftRadius: 15,
                cursor: "pointer",
              }}
              onClick={() => {
                setTab("cor");
              }}
            >
              <Typography
                marginTop="20px"
                sx={{
                  color: isTab === "cor" ? "#fff" : "#9E9E9E",
                  fontSize: isBreak ? "1.6vw" : 24,
                  fontWeight: 600,
                }}
              >
                코디
              </Typography>
            </Box>
            <Box
              width="48%"
              height={isBreak ? "6vw" : "80px"}
              sx={{
                boxShadow:
                  isTab === "feed"
                    ? "none"
                    : "0px -5px 10px rgba(0, 0, 0, 0.1)",
                bgcolor: isTab === "feed" ? "#2642FF" : "#fff",
                borderTopRightRadius: 15,
                borderTopLeftRadius: 15,
                cursor: "pointer",
              }}
              onClick={() => {
                setTab("feed");
              }}
            >
              <Typography
                marginTop="20px"
                sx={{
                  color: isTab === "feed" ? "#fff" : "#9E9E9E",
                  fontSize: isBreak ? "1.6vw" : 24,
                  fontWeight: 600,
                }}
              >
                피드백 리포트
              </Typography>
            </Box>
          </Grid>
        </Box>
        <Box width="100%" sx={{ bgcolor: "#2642FF" }}>
          <Box margin="0px auto" maxWidth="1447px" sx={{ bgcolor: "#2642FF" }}>
            {isTab === "cor" ? (
              <Box
                position="relative"
                height={isBreak ? "47.3vw" : "685px"}
                width="100%"
                visibility={isTab === "cor" ? "visible" : "hidden"}
              >
                <Box
                  position="relative"
                  top={isBreak ? "7.1vw" : "103px"}
                  left={isBreak ? "10.3vw" : "149px"}
                  width="50%"
                >
                  <Box position="absolute" top="-18px" left="-20px">
                    <Image src={Small} width="20px" />
                  </Box>
                  <Typography
                    textAlign="initial"
                    sx={{
                      fontFamily: "GmarketB",
                      fontSize: isBreak ? "2.5vw" : 36,
                      lineHeight: 1.3,
                    }}
                    color="white"
                  >
                    코디 선생님의 1:1 집중관리와
                    <br /> 학생-학부모 상담을 통해
                    <br />
                    학습효과의 시너지를 경험하세요!
                  </Typography>
                </Box>
                <Box
                  position="absolute"
                  width="26.2%"
                  bottom="-5px"
                  left="10%"
                  visibility={isTab === "cor" ? "visible" : "hidden"}
                >
                  <Image
                    src={CordiImage}
                    width={!isBreak ? "379px" : "26.2vw"}
                  />
                </Box>
                <Box
                  position="relative"
                  width={isBreak ? "37.3vw" : "540px"}
                  height={isBreak ? "29vw" : "420px"}
                  top="-10px"
                  left={isBreak ? "51.1vw" : "739px"}
                >
                  <Stack>
                    <Box>
                      <Stack direction="row" spacing={1}>
                        <Image src={ThumbIcon} width="6.5%" />
                        <Typography
                          textAlign="initial"
                          sx={{
                            fontSize: isBreak ? "2.5vw" : 36,
                            lineHeight: 1.3,
                            fontWeight: "bold",
                          }}
                          color="#FFC234"
                        >
                          끝난 수업도 완벽하게.
                        </Typography>
                      </Stack>
                      <Typography
                        textAlign="initial"
                        marginLeft="8%"
                        color="white"
                        sx={{
                          fontSize: isBreak ? "1.2vw" : 18,
                          fontWeight: 400,
                        }}
                      >
                        전담 교육 전문가 코디 선생님이 수업에 대한 리뷰와 개선
                        방안에 대해 코칭해 드립니다.
                      </Typography>
                    </Box>
                    <Box marginTop={isBreak ? "4.1vw" : "60px"}>
                      <Stack direction="row" spacing={1}>
                        <Image src={smallFaceIcon} width="6.5%" />
                        <Typography
                          textAlign="initial"
                          sx={{
                            fontSize: isBreak ? "2.5vw" : 36,
                            lineHeight: 1.3,
                            fontWeight: "bold",
                          }}
                          color="#FFC234"
                        >
                          학습 계획은 탄탄하게.
                        </Typography>
                      </Stack>
                      <Typography
                        textAlign="initial"
                        marginLeft="8%"
                        color="white"
                        sx={{
                          fontSize: isBreak ? "1.2vw" : 18,
                          fontWeight: 400,
                        }}
                      >
                        매 분기별 코디선생님과 함께 학습로드맵을 작성합니다.
                        작성된 로드맵을 바탕으로 학부모님과의 상담이 진행돼요.
                      </Typography>
                    </Box>
                    <Box marginTop={isBreak ? "4.1vw" : "60px"}>
                      <Stack direction="row" spacing={1}>
                        <Image src={HandIcon} width="6.5%" />

                        <Typography
                          textAlign="initial"
                          sx={{
                            fontSize: isBreak ? "2.5vw" : 36,
                            lineHeight: 1.3,
                            fontWeight: "bold",
                          }}
                          color="#FFC234"
                        >
                          공부 습관은 철저하게.
                        </Typography>
                      </Stack>
                      <Typography
                        textAlign="initial"
                        marginLeft="8%"
                        color="white"
                        sx={{
                          fontSize: isBreak ? "1.2vw" : 18,
                          fontWeight: 400,
                        }}
                      >
                        숙제관리, 학습습관 형성, 취약점 분석 및 보완 등의
                        <br /> 멘토링이 진행돼요.
                      </Typography>
                    </Box>
                  </Stack>
                </Box>
              </Box>
            ) : (
              <Box
                position="relative"
                height={isBreak ? "47.3vw" : "685px"}
                width="100%"
                visibility={isTab === "cor" ? "hidden" : "visible"}
              >
                <Box position="absolute" top="33%" left="10%">
                  <Box position="relative">
                    <Typography
                      fontFamily="GmarketB"
                      color="#fff"
                      fontSize={isBreak ? "2.5vw" : "36px"}
                      lineHeight={1.67}
                      fontWeight={400}
                      textAlign="initial"
                    >
                      피드백 리포트를 통해
                      <br />
                      학습 성취도를 확인해보세요.
                    </Typography>
                    <Typography
                      textAlign="initial"
                      color="#fff"
                      marginTop="5%"
                      lineHeight={1.48}
                      fontWeight={400}
                      sx={{
                        fontSize: isBreak ? "1.2vw" : 18,
                      }}
                    >
                      수업이 끝나면 진행된 강의 영상과 진도내용, 학습태도,
                      과제물 수행도 <br /> 등을 담은 피드백 리포트를
                      발송해드립니다.
                      <br />
                      매주 차곡차곡 쌓이는 피드백리포트와 함께 쑥쑥 자라나는
                      <br />
                      학생분들의 실력을 한눈에 확인해 보세요.
                    </Typography>
                    <Box position="absolute" top="-13px" left="-15px">
                      <Image src={Small} width="15px" />
                    </Box>
                  </Box>
                </Box>
                <Box width="100%">
                  <Image src={FeedBackImage} width="100%" />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box
        margin="0px auto"
        maxWidth="1447px"
        height="66vw"
        maxHeight="948px"
        padding={isBreak ? "8.35vw 13.1vw" : "120px 190px"}
        position="relative"
        sx={{ bgcolor: "#fff" }}
      >
        <Grid container justifyContent="center">
          <Box position="relative">
            <Box position="absolute" top="-18px" left="-20px">
              <Image src={Small} width="20px" />
            </Box>
            <Stack direction="row">
              <Typography variant="h1" color="#2642FF">
                편리하고 효과적
              </Typography>
              <Typography variant="h1">인 비대면 수업을 만나보세요.</Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid container justifyContent="space-between" marginTop="7%">
          <Box width="28%">
            <Image src={CommuIcon} width="100%" />
            <Typography
              marginTop="10%"
              sx={{ fontSize: isBreak ? "1.4vw" : 20, fontWeight: 700 }}
            >
              온라인 수업 전용앱 제공
            </Typography>
            <Typography
              marginTop="3%"
              color="#222222"
              sx={{ fontSize: isBreak ? "1.2vw" : 18 }}
            >
              비대면 과외에 특화된 <br /> 수업 전용 프로그램을 통해 <br />
              최고의 학습 경험을 제공해드려요.
            </Typography>
          </Box>
          <Box width="28%">
            <Image src={RemindIcon} width="100%" />
            <Typography
              marginTop="10%"
              sx={{ fontSize: isBreak ? "1.4vw" : 20, fontWeight: 700 }}
            >
              간편하고 효과적인 복습
            </Typography>
            <Typography
              marginTop="3%"
              color="#222222"
              sx={{ fontSize: isBreak ? "1.2vw" : 18 }}
            >
              수업한 내용을 자동으로 녹화하여 <br /> 언제든지 다시 보고 복습할
              수 <br />
              있어 학습 능률이 올라갑니다.
            </Typography>
          </Box>
          <Box width="28%">
            <Image src={DeviceIcon} width="100%" />
            <Typography
              marginTop="10%"
              sx={{ fontSize: isBreak ? "1.4vw" : 20, fontWeight: 700 }}
            >
              가지고 있는 기기 그대로 !
            </Typography>
            <Typography
              marginTop="3%"
              color="#222222"
              sx={{ fontSize: isBreak ? "1.2vw" : 18 }}
            >
              아이패드, 갤럭시 탭 등 태블릿은 <br /> 물론으로, 노트북, PC에서도{" "}
              <br /> 그대로 수업을 들으실 수 있습니다.
            </Typography>
          </Box>
        </Grid>
        <Button
          variant="contained"
          sx={{
            position: "absolute",
            bottom: "12%",
            left: "44%",
            bgcolor: "#2642FF",
            color: "#fff",
            fontWeight: 600,
            fontSize: isBreak ? "1.3vw" : 18,
            height: isBreak ? "4vw" : 54,
            borderRadius: 10,
            ":hover": {
              bgcolor: "#1b2eb2",
            },
          }}
          onClick={() => {
            window.open(
              "https://info.teachus.co.kr/594f72eee0a74395857fbcad49edf080"
            );
          }}
        >
          전용 앱 자세히 보기
        </Button>
      </Box>
      <Box width="100%" sx={{ bgcolor: "#F8F8F8" }}>
        <Box
          margin="0px auto"
          maxWidth="1447px"
          height="44vw"
          maxHeight="630px"
          position="relative"
          padding={
            isBreak ? "10.8vw 11.1vw 7.1vw 11.2vw" : "156px 161px 103px 162px"
          }
          sx={{ bgcolor: "#F8F8F8" }}
        >
          <Grid container justifyContent="space-between">
            <Box width={isBreak ? "32.1vw" : "465px"} position="relative">
              <Box position="absolute" top="-18px" left="-20px">
                <Image src={Small} width="20px" />
              </Box>
              <Typography
                variant="h1"
                textAlign="initial"
                sx={{ lineHeight: "60px" }}
              >
                가장 합리적인
              </Typography>
              <Typography
                textAlign="initial"
                variant="h1"
                sx={{ lineHeight: "60px" }}
              >
                가격으로 제공합니다.
              </Typography>
              <Typography
                marginTop="5%"
                textAlign="initial"
                color="#000000"
                sx={{ fontSize: isBreak ? "1.2vw" : 18 }}
              >
                티쳐스는 수수료를 최소화하여 <br /> 학생들의 수업비 부담을
                덜고자 합니다. <br />
                선생님 별로 다양한 가격대의 수업료를 통해 선택의 폭을
                넓혔습니다.
                <br />
                타사와 비교해 보시면 현명한 선택임을 확인해 보실 수 있습니다.
              </Typography>
              <Typography
                marginTop="12%"
                textAlign="initial"
                color="#C4C4C4"
                sx={{ fontSize: isBreak ? "1.1vw" : 16 }}
              >
                *주 2회 60분, 한 달 수업료 기준, 강사별 상이
              </Typography>
            </Box>
            <Image src={PriceImage} width={isBreak ? "37.6vw" : "544px"} />
          </Grid>
        </Box>
      </Box>
      <Box
        margin="0px auto"
        maxWidth="1447px"
        height="auto"
        paddingX={isBreak ? "5.8vw" : "85px"}
        paddingY={isBreak ? "8.3vw" : "120px"}
        sx={{ bgcolor: "#FFF" }}
      >
        <Grid container justifyContent="center" marginBottom="20px">
          <Stack direction="row" position="relative">
            <Box position="absolute" top="-18px" left="-20px">
              <Image src={Small} width="20px" />
            </Box>
            <Typography
              variant="h1"
              color="#2642FF"
              sx={{ lineHeight: "60px" }}
            >
              생생한 수업 후기
            </Typography>
            <Typography variant="h1" sx={{ lineHeight: "60px" }}>
              를 확인해 보세요!
            </Typography>
          </Stack>
        </Grid>
        <Box position="relative" paddingBottom="56.25%">
          <iframe
            style={{ position: "absolute", top: "0", left: "0" }}
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/llrUktd4s3A"
          ></iframe>
        </Box>
        <Typography
          marginTop="1%"
          color="#C4C4C4"
          sx={{ fontSize: isBreak ? "1.2vw" : 18 }}
        >
          티쳐스 이용 후기 영상
        </Typography>
        <Box position="relative">
          <div
            className="navigation-nexttt"
            style={{ position: "absolute", top: "40%", right: "0" }}
          >
            <Image src={Right} width="12px" />
          </div>
          <div
            className="navigation-prevvv"
            style={{ position: "absolute", top: "40%" }}
          >
            <Image src={Left} width="12px" />
          </div>
          <Swiper
            style={{
              marginTop: "5%",
              marginLeft: isBreak ? "5.6vw" : "80px",
              marginRight: isBreak ? "5.6vw" : "80px",
              wordBreak: "normal",
              // paddingLeft: isBreak ? "5.6vw" : "80px",
              // paddingRight: isBreak ? "5.6vw" : "80px",
            }}
            spaceBetween={isMob ? 10 : isBreak ? 20 : 30}
            slidesPerView={4}
            modules={[Navigation, A11y]}
            navigation={{
              nextEl: ".navigation-nexttt",
              prevEl: ".navigation-prevvv",
            }}
          >
            <SwiperSlide
              style={{
                width: isBreak ? "17.6vw" : "255px",
                height: isBreak ? "26.4vw" : "325px",
              }}
            >
              <Box
                borderRadius="10px"
                height="75%"
                bgcolor="rgba(255, 194, 52, 0.1)"
                padding={isBreak ? "2.2vw 1.9vw" : "33px 27px"}
                position="relative"
                sx={{ whiteSpace: "normal" }}
              >
                <Typography
                  color="#333333"
                  sx={{ fontSize: isBreak ? "1.1vw" : 18 }}
                  textAlign="initial"
                >
                  선생님께서 써주신 필기도 저장되고, 수업 영상도 몇번이고 돌려볼
                  수 있어서 편하네용.. 복습할 때 확실히 편해요.
                </Typography>
                <Stack
                  direction="row"
                  spacing={1}
                  position="absolute"
                  left="10%"
                  bottom={isBreak ? "5%" : "10%"}
                  alignItems="center"
                >
                  <Chip
                    variant="filled"
                    sx={{
                      bgcolor: "#FFB800",
                      color: "#2642FF",
                      height: isBreak ? "1.6vw" : 20,
                      fontSize: isBreak ? "0.9vw" : 12,
                      fontWeight: 600,
                    }}
                    label={"학생"}
                  />
                  <Typography sx={{ fontSize: isBreak ? "1.1vw" : 16 }}>
                    손*희
                  </Typography>
                </Stack>
              </Box>
            </SwiperSlide>
            <SwiperSlide
              style={{
                width: isBreak ? "17.6vw" : "255px",
                height: isBreak ? "26.4vw" : "325px",
              }}
            >
              <Box
                borderRadius="10px"
                height="75%"
                bgcolor="rgba(255, 194, 52, 0.1)"
                padding={isBreak ? "2.2vw 1.9vw" : "33px 27px"}
                position="relative"
                sx={{ whiteSpace: "normal" }}
              >
                <Typography
                  color="#333333"
                  sx={{ fontSize: isBreak ? "1.1vw" : 18 }}
                  textAlign="initial"
                >
                  코디쌤이 있어서 도움이 정말 많이 돼요! 학습관련 질문 뿐만
                  아니라 공부관련 고민상담도 해주셔서 항상 감사해요 ㅜㅜㅜ
                  귀찮게 해도 항상 친절하게 받아주시는 코디쌤...사랑합니다...♡
                </Typography>
                <Stack
                  direction="row"
                  spacing={1}
                  position="absolute"
                  left="10%"
                  bottom={isBreak ? "5%" : "10%"}
                  alignItems="center"
                >
                  <Chip
                    variant="filled"
                    sx={{
                      bgcolor: "#FFB800",
                      color: "#2642FF",
                      height: isBreak ? "1.6vw" : 20,
                      fontSize: isBreak ? "0.9vw" : 12,
                      fontWeight: 600,
                    }}
                    label={"학생"}
                  />
                  <Typography sx={{ fontSize: isBreak ? "1.1vw" : 16 }}>
                    김*진
                  </Typography>
                </Stack>
              </Box>
            </SwiperSlide>
            <SwiperSlide
              style={{
                width: isBreak ? "17.6vw" : "255px",
                height: isBreak ? "26.4vw" : "325px",
              }}
            >
              <Box
                height="75%"
                borderRadius="10px"
                bgcolor="rgba(0, 243, 128, 0.11)"
                padding={isBreak ? "2.2vw 1.9vw" : "33px 27px"}
                position="relative"
                sx={{ whiteSpace: "normal" }}
              >
                <Typography
                  color="#333333"
                  sx={{ fontSize: isBreak ? "1.1vw" : 18 }}
                  textAlign="initial"
                >
                  요즘 저희 애가 티쳐스로 공부 열심히 하고있어요~ 피드백
                  리포트를 정기적으로 제공해줘서 직접 수업에 참여하지 않더라도
                  우리 아이 진도나 학습 태도를 손쉽게 확인할 수 있는게
                  좋은것같아요^^
                </Typography>
                <Stack
                  direction="row"
                  spacing={1}
                  position="absolute"
                  left="10%"
                  bottom={isBreak ? "5%" : "10%"}
                  alignItems="center"
                >
                  <Chip
                    variant="filled"
                    sx={{
                      bgcolor: "#00F380",
                      color: "#fff",
                      height: isBreak ? "1.6vw" : 20,
                      fontSize: isBreak ? "0.9vw" : 12,
                      fontWeight: 600,
                    }}
                    label={"학부모"}
                  />
                  <Typography sx={{ fontSize: isBreak ? "1.1vw" : 16 }}>
                    김*영
                  </Typography>
                </Stack>
              </Box>
            </SwiperSlide>
            <SwiperSlide
              style={{
                width: isBreak ? "17.6vw" : "255px",
                height: isBreak ? "26.4vw" : "325px",
              }}
            >
              <Box
                height="75%"
                borderRadius="10px"
                bgcolor="rgba(255, 194, 52, 0.1)"
                padding={isBreak ? "2.2vw 1.9vw" : "33px 27px"}
                position="relative"
                sx={{ whiteSpace: "normal" }}
              >
                <Typography
                  color="#333333"
                  sx={{ fontSize: isBreak ? "1.1vw" : 18 }}
                  textAlign="initial"
                >
                  코디쌤이 있어서 도움이 정말 많이 돼요! 학습관련 질문 뿐만
                  아니라 공부관련 고민상담도 해주셔서 항상 감사해요 ㅜㅜㅜ
                </Typography>
                <Stack
                  direction="row"
                  spacing={1}
                  position="absolute"
                  left="10%"
                  bottom={isBreak ? "5%" : "10%"}
                  alignItems="center"
                >
                  <Chip
                    variant="filled"
                    sx={{
                      bgcolor: "#FFB800",
                      color: "#2642FF",
                      height: isBreak ? "1.6vw" : 20,
                      fontSize: isBreak ? "0.9vw" : 12,
                      fontWeight: 600,
                    }}
                    label={"학생"}
                  />
                  <Typography sx={{ fontSize: isBreak ? "1.1vw" : 16 }}>
                    이*영
                  </Typography>
                </Stack>
              </Box>
            </SwiperSlide>
            <SwiperSlide
              style={{
                width: isBreak ? "17.6vw" : "255px",
                height: isBreak ? "26.4vw" : "325px",
              }}
            >
              <Box
                borderRadius="10px"
                height="75%"
                bgcolor="rgba(255, 194, 52, 0.1)"
                padding={isBreak ? "2.2vw 1.9vw" : "33px 27px"}
                position="relative"
                sx={{ whiteSpace: "normal" }}
              >
                <Typography
                  color="#333333"
                  sx={{ fontSize: isBreak ? "1.1vw" : 18 }}
                  textAlign="initial"
                >
                  딴데에서 온라인과외 듣다가 선생님이 너무 저랑 안맞아서
                  티쳐스로 옮겼는데 여기 쌤들은 다 너무 좋으셔용... 모르는
                  문제도 차근히 알려주시고 친근하게 해주셔서 수업이 넘
                  재미있어용!!
                </Typography>
                <Stack
                  direction="row"
                  spacing={1}
                  position="absolute"
                  left="10%"
                  bottom={isBreak ? "5%" : "10%"}
                  alignItems="center"
                >
                  <Chip
                    variant="filled"
                    sx={{
                      bgcolor: "#FFB800",
                      color: "#2642FF",
                      height: isBreak ? "1.6vw" : 20,
                      fontSize: isBreak ? "0.9vw" : 12,
                      fontWeight: 600,
                    }}
                    label={"학생"}
                  />
                  <Typography sx={{ fontSize: isBreak ? "1.1vw" : 16 }}>
                    김*희
                  </Typography>
                </Stack>
              </Box>
            </SwiperSlide>
          </Swiper>
          <Typography
            marginTop="1%"
            color="#C4C4C4"
            sx={{ fontSize: isBreak ? "1.2vw" : 18 }}
          >
            본 후기는 티쳐스 알파 테스트 버전 이용자 후기입니다.
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default StudentWebHome;
