import React from "react";
import logo from "./logo.svg";
import "./App.css";
import withRoot from "./withRoot";
import HomeSt from "./page/HomeSt";
import HomeTc from "./page/HomeTc";
import Miricanvas from "./page/Miricanvas";
import Home from "./page/Home";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Switch>
        <Route exact path="/" component={HomeSt} />
        <Route exact path="/teacher" component={HomeTc} />
        <Route exact path="/miricanvas" component={Miricanvas} />
      </Switch>
    </div>
  );
}

export default withRoot(App);
