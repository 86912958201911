import React from "react";
import { useMediaQuery } from "@mui/material";
import TeacherWebHome from "./TeacherWebHome";
import TeacherMobHome from "./TeacherMobHome";
import Home from "./Home";

function HomeTc() {
  const isMob = useMediaQuery("(max-width: 700px)");
  const isBreak = useMediaQuery("(max-width: 1447px)");
  const [state, setState] = React.useState("student");

  return (
    <Home state="teacher">
      {isMob ? (
        <TeacherMobHome setState={setState} />
      ) : (
        <TeacherWebHome setState={setState} />
      )}
    </Home>
  );
}

export default HomeTc;
