import React from "react";
import {
  Box,
  Button,
  Chip,
  Typography,
  Grid,
  Stack,
  useMediaQuery,
} from "@mui/material";
import Image from "../components/Image";
import { Navigation, Pagination, A11y } from "swiper";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import TeacherImage from "../images/teacher_main.png";
import FaceIcon from "../images/faceIcon.png";
import ADImage1 from "../images/AD/AD_Teacher_Mob.jpg";
import ADImage2 from "../images/AD/AD_Common_Mob.jpg";
import ADImage3 from "../images/AD/AD_Teacher_Mob_1.jpg";
import One from "../images/One.svg";
import Two from "../images/Two.svg";
import Three from "../images/Three.svg";
import Fee from "../images/Fee.png";
import MoreDevice from "../images/MoreDevice.png";
import StudentManage from "../images/StudentManage.png";
import FeeH from "../images/FeeH.png";
import FeeW from "../images/FeeW.png";
import Warm from "../images/Warm.png";
import FaceT from "../images/FaceT.png";
import SignIn from "../images/SignIn.png";
import Profile from "../images/Profile.png";
import Paper from "../images/Paper.png";
import { useHistory } from "react-router";

const TeacherMobHome = (props: any) => {
  const isMob = useMediaQuery("(max-width: 700px)");
  const isBreak = useMediaQuery("(max-width: 1447px)");
  const isBreak_ = useMediaQuery("(max-width: 800px)");
  const history = useHistory();

  const [isTab, setTab] = React.useState("cor");

  return (
    <div>
      <Box
        margin="0px auto"
        maxWidth="1447px"
        paddingY="40px"
        overflow="hidden"
        position="relative"
        sx={{ bgcolor: "#2642FF" }}
      >
        <Box paddingLeft="3vw">
          <Box marginTop="10.5vw" position="relative">
            <Typography variant="h1" color="#FFF" textAlign="initial">
              티쳐스에서는
              <br />
              비대면 과외를 진행해 주실
              <br />
              선생님을 찾고 있어요.
            </Typography>
            <Typography variant="h2" textAlign="initial" marginTop="4vw">
              SKY, 포항공대, 카이스트, 의예과, 치의예과에 <br /> 재학 또는
              졸업하신 선생님을 모집하고 있습니다.
            </Typography>
            <Grid container justifyContent="initial" marginTop="20px">
              <Button
                variant="contained"
                sx={{
                  bgcolor: "#fff",
                  color: "#2642FF",
                  fontWeight: 600,
                  fontSize: "3.5vw",
                  height: "10vw",
                  width: "40vw",
                  borderRadius: 10,
                  zIndex: 1,
                  ":hover": {
                    bgcolor: "#b2b2b2",
                  },
                }}
                onClick={() => {
                  window.open("https://app.teachus.co.kr");
                  document.cookie =
                    "teacher=teacher; path=/; domain=teachus.co.kr;";
                }}
              >
                선생님 등록하기
              </Button>
            </Grid>
          </Box>
          <Grid container justifyContent="center" marginTop="8vw">
            <Image src={TeacherImage} width={"70%"} />
          </Grid>
        </Box>
      </Box>
      <Box margin="0px auto" maxWidth="1447px" marginBottom="-5px">
        <Swiper
          // install Swiper modules
          modules={[Navigation, Pagination, Autoplay, A11y]}
          spaceBetween={0}
          slidesPerView={1}
          autoplay={{ delay: 5000 }}
          pagination={{ clickable: true }}
          style={{ cursor: "pointer" }}
        >
          <SwiperSlide
            onClick={() => {
              window.open(
                "https://info.teachus.co.kr/4c70f96c60714f76862f71b92c11e51b"
              );
            }}
          >
            <Image src={ADImage1} width="100%" />
          </SwiperSlide>
          <SwiperSlide>
            <Image src={ADImage2} width="100%" />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => {
              window.open(
                "https://info.teachus.co.kr/5a2a52c0bd7847b7a615af2250e11601"
              );
            }}
          >
            <Image src={ADImage3} width="100%" />
          </SwiperSlide>
        </Swiper>
      </Box>
      <Box
        margin="0px auto"
        maxWidth="1447px"
        height="21.3vw"
        paddingX="10%"
        paddingTop="6.5vw"
        sx={{ cursor: "pointer", bgcolor: "#FFC234" }}
        onClick={() => {
          history.push("/");
        }}
      >
        <Grid container justifyContent="center">
          <Image src={FaceIcon} width="10%" />
          <Typography
            variant="h2"
            color="white"
            fontWeight={600}
            marginLeft="10px"
            marginTop="8px"
          >
            학생이시라면, 여기를 클릭해 주세요!
          </Typography>
        </Grid>
      </Box>
      <Box
        margin="0px auto"
        maxWidth="1447px"
        position="relative"
        paddingY="20%"
        sx={{ bgcolor: "#fff" }}
      >
        <Typography variant="h1">현재 모집 중인 대학</Typography>
        <Typography marginTop="3%" sx={{ fontSize: "3.5vw" }}>
          해당 대학의 재학생 및 졸업생
          <br />
          선생님을 모집하고 있습니다.
        </Typography>
        <Grid container marginTop="5%" paddingLeft="5%">
          <Chip
            sx={{
              border: "2px solid #2642FF",
              bgcolor: "#fff",
              width: "30%",
              height: "12vw",
              marginRight: "2vw",
              marginBottom: "5vw",
              borderRadius: 10,
              color: "#2642FF",
              fontSize: "3.5vw",
              fontWeight: 600,
            }}
            label="서울대"
          />
          <Chip
            sx={{
              border: "2px solid #2642FF",
              bgcolor: "#fff",
              width: "30%",
              height: "12vw",
              marginRight: "2vw",
              marginBottom: "5vw",
              borderRadius: 10,
              color: "#2642FF",
              fontSize: "3.5vw",
              fontWeight: 600,
            }}
            label="고려대"
          />
          <Chip
            sx={{
              border: "2px solid #2642FF",
              bgcolor: "#fff",
              width: "30%",
              height: "12vw",
              marginRight: "2vw",
              marginBottom: "5vw",
              borderRadius: 10,
              color: "#2642FF",
              fontSize: "3.5vw",
              fontWeight: 600,
            }}
            label="연세대"
          />
          <Chip
            sx={{
              border: "2px solid #2642FF",
              bgcolor: "#fff",
              width: "30%",
              height: "12vw",
              marginRight: "2vw",
              marginBottom: "5vw",
              borderRadius: 10,
              color: "#2642FF",
              fontSize: "3.5vw",
              fontWeight: 600,
            }}
            label="카이스트"
          />
          <Chip
            sx={{
              border: "2px solid #2642FF",
              bgcolor: "#fff",
              width: "30%",
              height: "12vw",
              marginRight: "2vw",
              marginBottom: "5vw",
              borderRadius: 10,
              color: "#2642FF",
              fontSize: "3.5vw",
              fontWeight: 600,
            }}
            label="포항공대"
          />
          <Chip
            sx={{
              border: "2px solid #2642FF",
              bgcolor: "#fff",
              width: "30%",
              height: "12vw",
              marginRight: "2vw",
              marginBottom: "5vw",
              borderRadius: 10,
              color: "#2642FF",
              fontSize: "3.5vw",
              fontWeight: 600,
            }}
            label="의대"
          />
          <Chip
            sx={{
              border: "2px solid #2642FF",
              bgcolor: "#fff",
              width: "30%",
              height: "12vw",
              marginRight: "2vw",
              marginBottom: "5vw",
              borderRadius: 10,
              color: "#2642FF",
              fontSize: "3.5vw",
              fontWeight: 600,
            }}
            label="치의대"
          />
          <Chip
            sx={{
              border: "2px solid #2642FF",
              bgcolor: "#fff",
              width: "30%",
              height: "12vw",
              marginRight: "2vw",
              marginBottom: "5vw",
              borderRadius: 10,
              color: "#2642FF",
              fontSize: "3.5vw",
              fontWeight: 600,
            }}
            label="한의대"
          />
        </Grid>
      </Box>
      <Box
        margin="0px auto"
        maxWidth="1447px"
        paddingY="21.3vw"
        position="relative"
        sx={{ bgcolor: "#F8F8F8" }}
      >
        <Grid container justifyContent="center">
          <Stack direction="row">
            <Typography variant="h1">이런&nbsp;</Typography>
            <Typography variant="h1" color="#2642FF">
              선생님
            </Typography>
            <Typography variant="h1">께&nbsp;</Typography>
          </Stack>
        </Grid>
        <Grid container justifyContent="center">
          <Stack direction="row">
            <Typography variant="h1" color="#2642FF">
              티쳐스를 추천
            </Typography>
            <Typography variant="h1">해요.</Typography>
          </Stack>
        </Grid>
        <Box height="10.7vw" />
        <Grid container justifyContent="center">
          <Box
            position="relative"
            width="90.9vw"
            height="142.1vw"
            borderRadius="10px"
            bgcolor="#fff"
            paddingTop="18.9vw"
          >
            <Box width="17.7%" position="absolute" top="-5%" left="41.1%">
              <Image src={One} width="100%" />
            </Box>
            <Button
              variant="contained"
              sx={{
                position: "absolute",
                bottom: "5%",
                left: "4.5%",
                bgcolor: "#2642FF",
                color: "#fff",
                height: "14.1vw",
                width: "84.3vw",
                borderRadius: 10,
                ":hover": {
                  bgcolor: "#1b2eb2",
                },
              }}
              onClick={() => {
                window.open(
                  "https://info.teachus.co.kr/7f7dbc65ca2446718fb2b551dcc1bfae"
                );
              }}
            >
              <Typography sx={{ fontWeight: 600, fontSize: "5.3vw" }}>
                수수료 정책 자세히보기
              </Typography>
            </Button>
            <Typography fontWeight={700} fontSize="5.3vw">
              기존 비대면 과외 플랫폼의
            </Typography>
            <Grid container justifyContent="center">
              <Stack direction="row">
                <Typography fontWeight={700} fontSize="5.3vw" color="#2642FF">
                  수수료가 부담
                </Typography>
                <Typography fontWeight={700} fontSize="5.3vw">
                  되었던 선생님
                </Typography>
              </Stack>
            </Grid>
            <Typography marginTop="5%" fontWeight={400} fontSize="4vw">
              티쳐스는 합리적인 수수료로 서비스를 제공해요
            </Typography>
            <Box position="absolute" bottom="18%">
              <Image src={Fee} width="86.3%" />
            </Box>
          </Box>
          <Box
            marginTop="18.1vw"
            position="relative"
            width="90.9vw"
            height="142.1vw"
            borderRadius="10px"
            bgcolor="#fff"
            paddingTop="18.9vw"
          >
            <Box width="17.7%" position="absolute" top="-5%" left="41.1%">
              <Image src={Two} width="100%" />
            </Box>
            <Button
              variant="contained"
              sx={{
                position: "absolute",
                bottom: "5%",
                left: "4.5%",
                bgcolor: "#2642FF",
                color: "#fff",
                height: "14.1vw",
                width: "84.3vw",
                borderRadius: 10,
                ":hover": {
                  bgcolor: "#1b2eb2",
                },
              }}
              onClick={() => {
                window.open(
                  "https://info.teachus.co.kr/GUIDE-043de4901e0540008d98fba68318e822"
                );
              }}
            >
              <Typography sx={{ fontWeight: 600, fontSize: "5.3vw" }}>
                비대면 수업 가이드
              </Typography>
            </Button>
            <Grid container justifyContent="center">
              <Stack direction="row">
                <Typography fontWeight={700} fontSize="5.3vw">
                  수업용&nbsp;
                </Typography>
                <Typography fontWeight={700} fontSize="5.3vw" color="#2642FF">
                  기기를 추가 구매
                </Typography>
                <Typography fontWeight={700} fontSize="5.3vw">
                  하는 것이
                </Typography>
              </Stack>
            </Grid>
            <Grid container justifyContent="center">
              <Stack direction="row">
                <Typography fontWeight={700} fontSize="5.3vw" color="#2642FF">
                  부담
                </Typography>
                <Typography fontWeight={700} fontSize="5.3vw">
                  스러운 선생님
                </Typography>
              </Stack>
            </Grid>
            <Typography marginTop="5%" fontWeight={400} fontSize="4vw">
              Windows, MAC, iOS, Android 등 OS
              <br /> 구분 없이 서비스를 지원해요.
            </Typography>
            <Box position="absolute" bottom="18%">
              <Image src={MoreDevice} width="86.3%" />
            </Box>
          </Box>
          <Box
            marginTop="18.1vw"
            position="relative"
            width="90.9vw"
            height="142.1vw"
            borderRadius="10px"
            bgcolor="#fff"
            paddingTop="18.9vw"
          >
            <Box width="17.7%" position="absolute" top="-5%" left="41.1%">
              <Image src={Three} width="100%" />
            </Box>
            <Button
              variant="contained"
              sx={{
                position: "absolute",
                bottom: "5%",
                left: "4.5%",
                bgcolor: "#2642FF",
                color: "#fff",
                height: "14.1vw",
                width: "84.3vw",
                borderRadius: 10,
                ":hover": {
                  bgcolor: "#1b2eb2",
                },
              }}
              onClick={() => {
                window.open(
                  "https://info.teachus.co.kr/b2ffd727dcbd43c78215038ae975e421"
                );
              }}
            >
              <Typography sx={{ fontWeight: 600, fontSize: "5.3vw" }}>
                비대면 코디 알아보기
              </Typography>
            </Button>
            <Grid container justifyContent="center">
              <Stack direction="row">
                <Typography fontWeight={700} fontSize="5.3vw">
                  수업 외&nbsp;
                </Typography>
                <Typography fontWeight={700} fontSize="5.3vw" color="#2642FF">
                  학생 관리가 어렵고
                </Typography>
              </Stack>
            </Grid>
            <Grid container justifyContent="center">
              <Stack direction="row">
                <Typography fontWeight={700} fontSize="5.3vw" color="#2642FF">
                  부담스러운
                </Typography>
                <Typography fontWeight={700} fontSize="5.3vw">
                  &nbsp;선생님
                </Typography>
              </Stack>
            </Grid>
            <Typography marginTop="5%" fontWeight={400} fontSize="4vw">
              티쳐스에서는 코디가 학부모상담 및<br /> 학생관리를 대신 진행해요.
            </Typography>
            <Box position="absolute" bottom="18%">
              <Image src={StudentManage} width="86.3%" />
            </Box>
          </Box>
        </Grid>
      </Box>
      <Box
        margin="0px auto"
        maxWidth="1447px"
        paddingY="21.3vw"
        paddingX="11.3vw"
        position="relative"
        sx={{ bgcolor: "#fff" }}
      >
        <Typography variant="h1">
          잘가르치는 만큼 많이
          <br /> 벌어가세요.
        </Typography>
        <Grid marginTop="21.3vw" container justifyContent="center">
          <Box width="77.3vw">
            <Grid container justifyContent="center">
              <Stack direction="row">
                <Typography fontSize="4.8vw" fontWeight={700}>
                  과외비 시급은,&nbsp;
                </Typography>
                <Typography fontSize="4.8vw" fontWeight={700} color="#2642FF">
                  직접 결정하세요!
                </Typography>
              </Stack>
            </Grid>
            <Image src={FeeH} width="100%" />
          </Box>
          <Box width="77.3vw" marginTop="21.3vw">
            <Grid container justifyContent="center">
              <Stack direction="row">
                <Typography fontSize="4.8vw" fontWeight={700}>
                  타사대비&nbsp;
                </Typography>
                <Typography fontSize="4.8vw" fontWeight={700} color="#2642FF">
                  수수료 부담은 최소로!
                </Typography>
              </Stack>
            </Grid>
            <Image src={FeeW} width="100%" />
          </Box>
        </Grid>
      </Box>
      <Box
        margin="0px auto"
        maxWidth="1447px"
        height="178.1vw"
        paddingY="21.3vw"
        paddingLeft="4.3vw"
        position="relative"
        sx={{ bgcolor: "#2642FF" }}
      >
        <Stack direction="row">
          <Typography color="#FFC234" variant="h1">
            비대면 과외
          </Typography>
          <Typography color="#fff" variant="h1">
            로
          </Typography>
        </Stack>
        <Typography color="#fff" textAlign="initial" variant="h1">
          시간을 아끼고 편하게
        </Typography>
        <Typography color="#fff" textAlign="initial" variant="h1">
          과외비 챙겨가세요
        </Typography>
        <Typography
          marginTop="5.3vw"
          color="#fff"
          textAlign="initial"
          fontSize="4.3vw"
          lineHeight={1.7}
        >
          집순이 {"&"} 집돌이 선생님도
          <br /> 본가에 거주 중인 지방러 선생님도
          <br /> 언제 어디서든 부담없이 과외하세요.
        </Typography>
        <Box position="absolute" right="4.3vw" bottom="21.3vw" width="75.2vw">
          <Image src={Warm} width="100%" />
        </Box>
      </Box>
      <Box
        margin="0px auto"
        maxWidth="1447px"
        paddingY="21.3vw"
        paddingX="4.3vw"
        position="relative"
        sx={{ bgcolor: "#fff" }}
      >
        <Grid container justifyContent="center">
          <Image src={FaceT} width="8.53vw" />
        </Grid>
        <Grid container justifyContent="center" marginTop="2.1vw">
          <Stack>
            <Typography variant="h1">티쳐스 선생님&nbsp; </Typography>
            <Typography variant="h1">&nbsp;되는 방법 !</Typography>
          </Stack>
        </Grid>
        <Grid marginTop="5.3vw" container justifyContent="center">
          <Button
            variant="contained"
            sx={{
              bgcolor: "#2642FF",
              color: "#fff",
              fontWeight: 600,
              fontSize: "3.2vw",
              height: "8vw",
              width: "34vw",
              borderRadius: 10,
              ":hover": {
                bgcolor: "#1b2eb2",
              },
            }}
            onClick={() => {
              window.open("https://app.teachus.co.kr");
              document.cookie =
                "teacher=teacher; path=/; domain=teachus.co.kr;";
            }}
          >
            지금 등록하기
          </Button>
        </Grid>
        <Grid container justifyContent="center" marginTop="21.3vw">
          <Box width="69.3vw">
            <Typography fontSize="4.3vw" fontWeight={700}>
              STEP 1
            </Typography>
            <Grid container justifyContent="center">
              <Stack direction="row">
                <Typography fontSize="5.3vw" fontWeight={700}>
                  강사로&nbsp;
                </Typography>
                <Typography fontSize="5.3vw" fontWeight={700} color="#2642FF">
                  회원가입
                </Typography>
              </Stack>
            </Grid>
            <Box height="8px" />
            <Image src={SignIn} width="100%" />
          </Box>
          <Box width="69.3vw" marginTop="21.3vw">
            <Typography fontSize="4.3vw" fontWeight={700}>
              STEP 2
            </Typography>
            <Grid container justifyContent="center">
              <Stack direction="row">
                <Typography fontSize="5.3vw" color="#2642FF" fontWeight={700}>
                  프로필&nbsp;
                </Typography>
                <Typography fontSize="5.3vw" fontWeight={700}>
                  등록
                </Typography>
              </Stack>
            </Grid>
            <Box height="8px" />
            <Image src={Profile} width="100%" />
          </Box>
          <Box width="69.3vw" marginTop="21.3vw">
            <Typography fontSize="4.3vw" fontWeight={700}>
              STEP 3
            </Typography>
            <Grid container justifyContent="center">
              <Stack direction="row">
                <Typography fontSize="5.3vw" color="#2642FF" fontWeight={700}>
                  필수 서류&nbsp;
                </Typography>
                <Typography fontSize="5.3vw" fontWeight={700}>
                  제출
                </Typography>
              </Stack>
            </Grid>
            <Box height="8px" />
            <Image src={Paper} width="100%" />
          </Box>
        </Grid>
        <Box height="21.3vw" />
        <Box
          width="100%"
          height="37.6vw"
          bgcolor="#F8F8F8"
          padding="4.4vw 8.7vw"
        >
          <Typography
            textAlign="initial"
            fontSize="8vw"
            color="#2642FF"
            fontWeight={700}
          >
            TIP.
          </Typography>
          <Box height="2.13vw" />
          <Typography textAlign="initial" fontSize="4.3vw">
            프로필을 잘 작성해주실 수록,
            <br /> 과외 성사율이 높아집니다.
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default TeacherMobHome;
