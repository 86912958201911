
function Miricanvas() {
  return (
    <iframe src="https://www.miricanvas.com/v/11211xh" width="100%" height={window.innerHeight-6} scrolling = "no" >
    </iframe>
  );
}

export default Miricanvas;

