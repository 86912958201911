import React from "react";
import {
  Box,
  Button,
  Chip,
  Typography,
  IconButton,
  Grid,
  Fab,
  Dialog,
  Stack,
  useMediaQuery,
} from "@mui/material";
import StudentWebHome from "./StudentWebHome";
import TeacherWebHome from "./TeacherWebHome";
import StudentMobHome from "./StudentMobHome";
import TeacherMobHome from "./TeacherMobHome";
import Home from "./Home";
import LogoIcon from "../images/Logo.svg";
import FABIcon from "../images/FAB.png";
import MoreImage from "../images/More.png";
import MoreImage_M from "../images/More_M.png";
import BottomLogo from "../images/Bottom_logo.png";
import Image from "../components/Image";
import FacebookIcon from "../images/Facebook.svg";
import InstaIcon from "../images/Instagram.svg";
import TwitIcon from "../images/Twitter.svg";

function HomeSt() {
  const isMob = useMediaQuery("(max-width: 700px)");
  const isBreak = useMediaQuery("(max-width: 1447px)");
  const [state, setState] = React.useState("student");

  return (
    <Home state="student">
      {isMob ? (
        <StudentMobHome setState={setState} />
      ) : (
        <StudentWebHome setState={setState} />
      )}
    </Home>
  );
}

export default HomeSt;
