import React from "react";
import {
  Box,
  Button,
  Chip,
  Typography,
  IconButton,
  Grid,
  Fab,
  Dialog,
  Stack,
  useMediaQuery,
} from "@mui/material";
import StudentWebHome from "./StudentWebHome";
import TeacherWebHome from "./TeacherWebHome";
import StudentMobHome from "./StudentMobHome";
import TeacherMobHome from "./TeacherMobHome";
import { useHistory } from "react-router";
import LogoIcon from "../images/Logo.svg";
import FABIcon from "../images/FAB.png";
import MoreImage from "../images/More.png";
import MoreImage_M from "../images/More_M.png";
import BottomLogo from "../images/Bottom_logo.png";
import Image from "../components/Image";
import FacebookIcon from "../images/Facebook.svg";
import InstaIcon from "../images/Instagram.svg";
import TwitIcon from "../images/Twitter.svg";

function Home(props: any) {
  const isMob = useMediaQuery("(max-width: 700px)");
  const isBreak = useMediaQuery("(max-width: 1447px)");
  const history = useHistory();
  const [state, setState] = React.useState(props.state);

  return (
    <div style={{ position: "relative" }}>
      <Fab
        variant="extended"
        color="primary"
        aria-label="add"
        style={{
          borderBottomLeftRadius: 40,
          borderTopLeftRadius: 40,
          borderBottomRightRadius: 0,
          borderTopRightRadius: 0,
          paddingRight: -20,
          width: isMob ? "48.8vw" : isBreak ? "19.3vw" : 280,
          height: isMob ? "10.7vw" : isBreak ? "5.5vw" : 80,
          backgroundColor: "#fff",
        }}
        sx={
          isMob
            ? {
                position: "fixed",
                bottom: "10vw",
                right: "10%",
                zIndex: 50,
              }
            : isBreak
            ? {
                position: "fixed",
                bottom: "6vw",
                right: "5%",
                zIndex: 50,
              }
            : {
                position: "fixed",
                bottom: "5vw",
                right: "5%",
                zIndex: 50,
              }
        }
        onClick={
          state === "student"
            ? () => {
                window.open("https://app.teachus.co.kr");
                document.cookie =
                  "teacher=student; path=/; domain=teachus.co.kr;";
              }
            : () => {
                window.open("https://app.teachus.co.kr");
                document.cookie =
                  "teacher=teacher; path=/; domain=teachus.co.kr;";
              }
        }
      >
        <Typography
          fontSize={isMob ? "4.3vw" : isBreak ? "1.7vw" : "25px"}
          color="#2642FF"
          fontWeight="bold"
          marginRight={isMob ? "5vw" : isBreak ? "1.4vw" : "20px"}
        >
          {state != "student" ? "티쳐스 앱 바로가기" : "티쳐스 시작하기"}
        </Typography>
        <Box
          sx={{
            marginRight: isMob ? "-15vw" : isBreak ? "-6.9vw" : "-100px",
            marginBottom: isMob ? "-1.1vw" : isBreak ? "-0.4vw" : "-6px",
          }}
        >
          <Image
            src={FABIcon}
            width={isMob ? "10.5vw" : isBreak ? "5.5vw" : "78px"}
          />
        </Box>
      </Fab>
      <Box width="100%" sx={{ bgcolor: "#2642FF" }}>
        <Box
          margin="0px auto"
          maxWidth="1447px"
          paddingTop={isBreak ? "2.7vw" : "40px"}
          overflow="hidden"
          position="relative"
          sx={{ bgcolor: "#2642FF" }}
        >
          <Grid
            container
            paddingX={isMob ? "3vw" : isBreak ? "7vw" : "140px"}
            justifyContent="space-between"
          >
            <img src={LogoIcon} width={"117px"} />
            {isMob ? null : (
              <Stack direction="row">
                <Button
                  sx={
                    state == "teacher"
                      ? {
                          cursor: "pointer",
                          bgcolor: "#fff",
                          color: "#2642FF",
                          marginRight: "10px",
                          fontWeight: 600,
                          borderRadius: 10,
                          height: 30,
                          ":hover": {
                            bgcolor: "#cccccc",
                          },
                        }
                      : {
                          cursor: "pointer",
                          bgcolor: "#FFC234",
                          color: "#2642FF",
                          marginRight: "10px",
                          fontWeight: 600,
                          borderRadius: 10,
                          height: 30,
                          ":hover": {
                            bgcolor: "#ce9c2a",
                          },
                        }
                  }
                  onClick={() => {
                    history.push("/");
                    setState("student");
                  }}
                >
                  STUDENT
                </Button>
                <Button
                  sx={
                    state == "teacher"
                      ? {
                          cursor: "pointer",
                          bgcolor: "#FFC234",
                          color: "#2642FF",
                          fontWeight: 600,
                          borderRadius: 10,
                          height: 30,
                          ":hover": {
                            bgcolor: "#ce9c2a",
                          },
                        }
                      : {
                          cursor: "pointer",
                          bgcolor: "#fff",
                          color: "#2642FF",
                          fontWeight: 600,
                          borderRadius: 10,
                          height: 30,
                          ":hover": {
                            bgcolor: "#cccccc",
                          },
                        }
                  }
                  onClick={() => {
                    history.push("/teacher");
                    setState("teacher");
                  }}
                >
                  TEACHER
                </Button>
              </Stack>
            )}
          </Grid>
        </Box>
      </Box>
      {props.children}
      <Box width="100%" sx={{ bgcolor: "#2642FF" }}>
        <Box
          margin="0px auto"
          maxWidth="1447px"
          position="relative"
          sx={{ bgcolor: "#2642FF" }}
        >
          <Image src={isMob ? MoreImage_M : MoreImage} width="100%" />
          {isMob ? (
            <>
              <Grid
                container
                justifyContent="center"
                position="absolute"
                top="30%"
              >
                <Stack direction="row">
                  <Typography
                    variant="h1"
                    color="#FFB721"
                    sx={{ lineHeight: "60px" }}
                  >
                    티쳐스
                  </Typography>
                  <Typography
                    variant="h1"
                    color="white"
                    sx={{ lineHeight: "60px" }}
                  >
                    가
                  </Typography>
                </Stack>
              </Grid>
              <Grid
                container
                justifyContent="center"
                position="absolute"
                top="38%"
              >
                <Typography
                  variant="h1"
                  color="white"
                  sx={{ lineHeight: "60px" }}
                >
                  더 궁금하시다면?
                </Typography>
              </Grid>
              <Typography
                position="absolute"
                top="48%"
                width="100%"
                color="#eeeeee"
                sx={{ fontSize: isBreak ? "3.5vw" : 18 }}
              >
                실시간 무료 채팅 상담을 신청하세요 !
              </Typography>
            </>
          ) : (
            <>
              <Grid
                container
                justifyContent="center"
                position="absolute"
                top="35%"
              >
                <Typography
                  variant="h1"
                  color="#FFB721"
                  sx={{ lineHeight: "60px" }}
                >
                  티쳐스
                </Typography>
                <Typography
                  variant="h1"
                  color="white"
                  sx={{ lineHeight: "60px" }}
                >
                  가 더 궁금하시다면?
                </Typography>
              </Grid>
              <Typography
                position="absolute"
                top="49%"
                width="100%"
                color="#eeeeee"
                sx={{ fontSize: isBreak ? "1.2vw" : 18 }}
              >
                실시간 채팅 상담을 통해 언제든지 친절하게 저희 서비스를 소개해
                드리겠습니다.
              </Typography>
            </>
          )}

          <Stack
            direction={isMob ? "column" : "row"}
            position="absolute"
            bottom={isMob ? "24%" : "23%"}
            right={isBreak ? (isMob ? "27%" : "34%") : "35.5%"}
          >
            <Button
              variant="contained"
              sx={{
                bgcolor: "#fff",
                color: "#2642FF",
                fontWeight: 600,
                fontSize: isMob ? "3.5vw" : isBreak ? "1.4vw" : 18,
                height: isMob ? "10vw" : isBreak ? "4vw" : 54,
                width: isMob ? "45vw" : isBreak ? "14.5vw" : 206,
                marginRight: isMob ? "0px" : isBreak ? "3.1vw" : "45px",
                borderRadius: 10,
                ":hover": {
                  bgcolor: "#cccccc",
                },
              }}
              onClick={
                state === "student"
                  ? () => {
                      window.open("https://app.teachus.co.kr");
                      document.cookie =
                        "teacher=student; path=/; domain=teachus.co.kr;";
                    }
                  : () => {
                      window.open("https://app.teachus.co.kr");
                      document.cookie =
                        "teacher=teacher; path=/; domain=teachus.co.kr;";
                    }
              }
            >
              티쳐스 앱 열기
            </Button>
            <Button
              variant="contained"
              sx={{
                bgcolor: "#FFC234",
                color: "#2642FF",
                fontWeight: 600,
                fontSize: isMob ? "3.5vw" : isBreak ? "1.4vw" : 18,
                height: isMob ? "10vw" : isBreak ? "4vw" : 54,
                width: isMob ? "45vw" : isBreak ? "16vw" : 206,
                marginTop: isMob ? "5.3vw" : "0px",
                borderRadius: 10,
                ":hover": {
                  bgcolor: "#ce9c2a",
                },
              }}
              onClick={() => {
                state === "teacher"
                  ? window.open("http://pf.kakao.com/_vexhCb/chat")
                  : window.open("http://pf.kakao.com/_vexhCb/chat");
              }}
            >
              실시간 채팅 상담
            </Button>
          </Stack>
        </Box>
      </Box>
      <Box width="100%" sx={{ bgcolor: "#000000" }}>
        <Box margin="0px auto" maxWidth="1447px" sx={{ bgcolor: "#000000" }}>
          <Box paddingX={isMob ? "5%" : "10%"} paddingY="7%">
            {isMob ? (
              <div>
                <Grid container justifyContent="space-between">
                  <Stack spacing={1}>
                    <Image src={BottomLogo} width="25vw" />
                    <Typography
                      color="#fff"
                      textAlign="initial"
                      sx={{ fontSize: "3vw", fontWeight: 300 }}
                    >
                      비대면 교육의 새로운 솔루션
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={2}>
                    <Image src={FacebookIcon} width="17px" />
                    <Image src={InstaIcon} width="17px" />
                    <Image src={TwitIcon} width="17px" />
                  </Stack>
                </Grid>
                <Box height="5vw" />
                <Stack spacing={1}>
                  <Stack direction="row" spacing={1}>
                    <Typography color="#fff" textAlign="end" variant="h4">
                      Contact
                    </Typography>
                    <Typography color="#fff" textAlign="end" variant="h6">
                      contact@teachus.co.kr
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <Typography color="#fff" variant="h5">
                      상호
                    </Typography>
                    <Typography color="#fff" variant="h6">
                      프로스에듀
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <Typography color="#fff" variant="h5">
                      대표자
                    </Typography>
                    <Typography color="#fff" variant="h6">
                      이지훈
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <Typography color="#fff" variant="h5">
                      고객센터
                    </Typography>
                    <Typography color="#fff" variant="h6">
                      카카오채널 "티쳐스(teachus)"
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <Typography color="#fff" variant="h5">
                      사업자등록번호
                    </Typography>
                    <Typography color="#fff" variant="h6">
                      322-21-01127
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <Typography color="#fff" variant="h5">
                      통신판매업 신고번호
                    </Typography>
                    <Typography color="#fff" variant="h6">
                      2022-서울노원-0935
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <Typography color="#fff" variant="h5">
                      주소
                    </Typography>
                    <Typography color="#fff" variant="h6">
                      서울특별시 성북구 고려대로 114, 3층 캠퍼스타운 스튜디오
                      5호점
                    </Typography>
                  </Stack>
                </Stack>
              </div>
            ) : (
              <div>
                <Grid container justifyContent="space-between">
                  <Stack>
                    <Image
                      src={BottomLogo}
                      width={isBreak ? "10.6vw" : "154px"}
                    />
                    <Typography
                      color="#fff"
                      textAlign="initial"
                      marginTop={isBreak ? "0.7vw" : "10px"}
                      variant="h6"
                    >
                      비대면 교육의 새로운 솔루션
                    </Typography>
                  </Stack>
                  <Stack>
                    <Typography color="#fff" textAlign="end" variant="h4">
                      Contact
                    </Typography>
                    <Typography
                      color="#fff"
                      textAlign="end"
                      marginTop={isBreak ? "0.7vw" : "10px"}
                      variant="h6"
                    >
                      contact@teachus.co.kr
                    </Typography>
                  </Stack>
                </Grid>
                <Box height={isBreak ? "4.1vw" : "60px"} />
                <Grid container justifyContent="space-between">
                  <Box>
                    <Stack direction="row" spacing={1}>
                      <Typography color="#fff" variant="h5">
                        상호
                      </Typography>
                      <Typography color="#fff" variant="h6">
                        프로스에듀
                      </Typography>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <Typography color="#fff" variant="h5">
                        대표자
                      </Typography>
                      <Typography color="#fff" variant="h6">
                        이지훈
                      </Typography>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <Typography color="#fff" variant="h5">
                        고객센터
                      </Typography>
                      <Typography color="#fff" variant="h6">
                        카카오채널 "티쳐스(teachus)"
                      </Typography>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <Typography color="#fff" variant="h5">
                        사업자등록번호
                      </Typography>
                      <Typography color="#fff" variant="h6">
                        322-21-01127
                      </Typography>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <Typography color="#fff" variant="h5">
                        통신판매업 신고번호
                      </Typography>
                      <Typography color="#fff" variant="h6">
                        2022-서울노원-0935
                      </Typography>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <Typography color="#fff" variant="h5">
                        주소
                      </Typography>
                      <Typography color="#fff" variant="h6">
                        서울특별시 성북구 고려대로 114, 3층 캠퍼스타운 스튜디오
                        5호점
                      </Typography>
                    </Stack>
                  </Box>
                  <Stack direction="row" spacing={2}>
                    <Image src={FacebookIcon} width="27px" />
                    <Image src={InstaIcon} width="27px" />
                    <Image src={TwitIcon} width="27px" />
                  </Stack>
                </Grid>
              </div>
            )}
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default Home;
