import styled from 'styled-components'

export interface Props {
  src: string
  center?: boolean
  fullWidth?: boolean
  height?: string
  width?: string
  maxWidth?: string
  onHeightChanged?: (height: number) => void
  rotateDegree?: number
}

const StyledImage = styled.img`
  max-width: ${(props: Props) => (props.maxWidth ? props.maxWidth : undefined)};
  margin: ${(props: Props) => (props.center ? '0 auto' : undefined)};
  display: ${(props: Props) => (props.center ? 'block' : undefined)};
  width: ${(props: Props) =>
    props.fullWidth ? '100%' : props.width ? props.width : undefined};
  height: ${(props: Props) => (props.height ? props.height : undefined)};
  object-fit: contain;
  outline: none;
  transform: ${(props: Props) =>
    props.rotateDegree ? `rotate(${props.rotateDegree}deg)` : undefined};
`

const Image = (props: Props) => {
  const { src, onHeightChanged } = props

  return <StyledImage {...props} src={src} />
}

export default Image
