import React from "react";
import {
  Box,
  Button,
  Chip,
  Typography,
  IconButton,
  Grid,
  Dialog,
  Stack,
  useMediaQuery,
} from "@mui/material";
import {
  ListTeachersQuery,
  FeeInfo,
  GetFeeInfoQuery,
  TeacherWithImage,
} from "../graphql";
import axios from "axios";
import TUAvatar from "../components/TUAvatar";
import Image from "../components/Image";
import { Navigation, Pagination, A11y } from "swiper";
import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useHistory } from "react-router";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import ADImage1 from "../images/AD/AD_Student_Mob_1.png";
import ADImage2 from "../images/AD/AD_Student_Mob_2.png";
import ADImage3 from "../images/AD/AD_Student_Mob_3.png";
import ADImage4 from "../images/AD/AD_Common_Mob.jpg";
import ADImage5 from "../images/AD/AD_Student_Mob.jpg";
import ADImage6 from "../images/AD/AD_Student_Mob_1.jpg";
import ADImage7 from "../images/AD/AD_Student_Mob_2.jpg";
import ADImage8 from "../images/AD/AD_Student_Mob_3.jpg";
import FaceIcon from "../images/faceIcon.png";
import S_FaceIcon from "../images/s_FaceIcon.png";
import K_FaceIcon from "../images/k_FaceIcon.png";
import Y_FaceIcon from "../images/y_FaceIcon.png";
import CordiImage from "../images/Cordi.png";
import ThumbIcon from "../images/Thumb.png";
import smallFaceIcon from "../images/Face.png";
import HandIcon from "../images/Hand.png";
import Small from "../images/Small.png";
import FeedBackImage from "../images/FeedBack_M.png";
import CommuIcon from "../images/Communication.png";
import RemindIcon from "../images/Remind.png";
import DeviceIcon from "../images/Device.png";
import TabletImage from "../images/Tablet_mob.png";
import PriceImage from "../images/Price.png";
import AfterImage from "../images/After_M.png";

const StudentMobHome = (props: any) => {
  const isMob = useMediaQuery("(max-width: 700px)");
  const isBreak = useMediaQuery("(max-width: 1447px)");
  const isBreak_ = useMediaQuery("(max-width: 800px)");
  const history = useHistory();

  const [isTab, setTab] = React.useState("cor");

  const [feeInfo, setFeeInfo] = React.useState<FeeInfo>();
  const [stuList, setStuList] = React.useState<
    (TeacherWithImage | null | undefined)[] | undefined | null
  >(undefined);
  const [subjects, setSubjects] = React.useState<string[]>([]);

  React.useEffect(() => {
    getFeeInfoFunction();
    getTeachersList();
  }, []);
  async function getFeeInfoFunction() {
    try {
      const apiData = (
        await axios.get(
          "https://4hyrv53rv0.execute-api.ap-northeast-2.amazonaws.com/dev/getFeeInfo-dev"
        )
      ).data as { data: GetFeeInfoQuery };

      if (apiData && apiData.data.getFeeInfo) {
        setFeeInfo(apiData.data.getFeeInfo);
        return;
      } else {
        return;
      }
    } catch (e) {
      //console.log(e);
    }
  }

  // 제안 가능한 강사들
  async function getTeachersList() {
    const { data } = await axios.post(
      "https://0ah3gq55m9.execute-api.ap-northeast-2.amazonaws.com/dev/items",
      { subject: subjects }
    );
    const apiData = {
      data: {
        listTeachers: {
          __typename: "ModelTeacherConnection",
          items: data,
          nextToken: null,
        },
      },
    } as { data: ListTeachersQuery };

    try {
      if (apiData?.data?.listTeachers) {
        setStuList(
          apiData.data.listTeachers.items?.map((teacher) => {
            return teacher && teacher.profileImage
              ? { ...teacher, imageURL: teacher.profileImage }
              : teacher;
          })
        );
      }
      return;
    } catch (e) {
      //console.log(e);
    }
  }

  return (
    <div style={{ overflow: "hidden" }}>
      <Box
        margin="0px auto"
        maxWidth="1447px"
        paddingTop="10vw"
        overflow="hidden"
        position="relative"
        sx={{ bgcolor: "#2642FF" }}
      >
        <Stack marginLeft="20px">
          <Box position="relative">
            <Box position="absolute" top="-20px" left="0px">
              <Image src={Small} width="15px" />
            </Box>
            <Typography textAlign="initial" variant="h2" marginBottom="3vw">
              SKY 선생님은 기본!
            </Typography>
            <Stack direction="row" marginBottom="5px">
              <Typography variant="h1" color="#FFC234">
                비대면 과외
              </Typography>
              <Typography variant="h1" color="#FFF">
                를 위한
              </Typography>
            </Stack>
            <Stack direction="row" marginBottom="3vw">
              <Typography variant="h1" color="#fff">
                최고의 선택,
              </Typography>
              <Typography variant="h1" color="#FFC234">
                &nbsp;티쳐스
              </Typography>
            </Stack>
            <Typography variant="h2" textAlign="initial">
              수업부터 학습관리까지
            </Typography>
            <Stack direction="row" marginBottom="3vw">
              <Typography
                variant="h2"
                textAlign="initial"
                sx={{ fontWeight: 600 }}
              >
                강사+코디
              </Typography>
              <Typography variant="h2" textAlign="initial">
                &nbsp;듀얼케어 시스템으로 완벽하게 !
              </Typography>
            </Stack>
            <Grid container justifyContent="initial" marginTop="20px">
              <Button
                variant="contained"
                sx={{
                  bgcolor: "#FFC234",
                  color: "#2642FF",
                  fontWeight: 600,
                  fontSize: "3.3vw",
                  height: "9vw",
                  width: "26vw",
                  borderRadius: 10,
                  zIndex: 1,
                  ":hover": {
                    bgcolor: "#ce9c2a",
                  },
                }}
                onClick={() => {
                  window.open("https://app.teachus.co.kr");
                  document.cookie =
                    "teacher=student; path=/; domain=teachus.co.kr;";
                }}
              >
                시작하기
              </Button>
            </Grid>
          </Box>
          <Box marginTop={"-20vw"}>
            <Image src={TabletImage} width={"100%"} />
          </Box>
        </Stack>
      </Box>
      <Box paddingY="auto" marginBottom="-5px">
        <Swiper
          // install Swiper modules
          modules={[Navigation, Pagination, Autoplay, A11y]}
          spaceBetween={0}
          slidesPerView={1}
          autoplay={{ delay: 5000 }}
          pagination={{ clickable: true }}
          style={{ cursor: "pointer" }}
        >
          {/* <SwiperSlide
            onClick={() => {
              window.open(
                "https://prosedu.notion.site/50-ab4d504fb3794c639a0b956de87df3c1"
              );
            }}
          >
            <Image src={ADImage1} width="100%" />
          </SwiperSlide> */}
          <SwiperSlide
            onClick={() => {
              window.open(
                "https://prosedu.notion.site/c8e35c25359544b9bc6372c64a103847"
              );
            }}
          >
            <Image src={ADImage3} width="100%" />
          </SwiperSlide>

          <SwiperSlide
            onClick={() => {
              window.open(
                "https://info.teachus.co.kr/3a4a945024a9453880e2043e903e1ad7"
              );
            }}
          >
            <Image src={ADImage2} width="100%" />
          </SwiperSlide>
          <SwiperSlide>
            <Image src={ADImage4} width="100%" />
          </SwiperSlide>
          <SwiperSlide
            onClick={() => {
              window.open(
                "https://info.teachus.co.kr/98e80b3594434c59be1c8984259992b5"
              );
            }}
          >
            <Image src={ADImage5} width="100%" />
          </SwiperSlide>
          <SwiperSlide>
            <Image src={ADImage6} width="100%" />
          </SwiperSlide>
          <SwiperSlide>
            <Image src={ADImage7} width="100%" />
          </SwiperSlide>
          <SwiperSlide>
            <Image src={ADImage8} width="100%" />
          </SwiperSlide>
        </Swiper>
      </Box>
      <Box
        margin="0px auto"
        maxWidth="1447px"
        height="21.3vw"
        paddingTop="6.5vw"
        sx={{ cursor: "pointer", bgcolor: "#FFC234" }}
        onClick={() => {
          history.push("/teacher");
        }}
      >
        <Grid container justifyContent="center">
          <Image src={FaceIcon} width="10%" />
          <Typography
            variant="h2"
            color="white"
            fontWeight={600}
            marginLeft="10px"
            marginTop="10px"
          >
            선생님이시라면, 여기를 클릭해 주세요!
          </Typography>
        </Grid>
      </Box>
      <Box
        margin="0px auto"
        height="210.3vw"
        paddingTop="20%"
        paddingLeft="4%"
        position="relative"
        sx={{ bgcolor: "#F8F8F8" }}
      >
        <Button
          variant="contained"
          sx={{
            position: "absolute",
            bottom: "5%",
            right: "30%",
            bgcolor: "#2642FF",
            color: "#fff",
            fontWeight: 600,
            fontSize: "3.5vw",
            height: "10vw",
            width: "40vw",
            borderRadius: 10,
            ":hover": {
              bgcolor: "#1b2eb2",
            },
          }}
          onClick={() => {
            window.open("https://app.teachus.co.kr");
            document.cookie =
              "teacher=search; path=/; max-age=5; domain=teachus.co.kr;";
          }}
        >
          선생님 찾아보기
        </Button>
        <Box position="relative">
          <Box position="absolute" top="-28px" left="0px">
            <Image src={Small} width="20px" />
          </Box>
          <Typography
            variant="h1"
            textAlign="initial"
            sx={{ lineHeight: "60px" }}
          >
            명문대 출신
          </Typography>
          <Stack direction="row">
            <Typography
              variant="h1"
              color="#2642FF"
              sx={{ lineHeight: "60px" }}
            >
              최고의 선생님
            </Typography>
            <Typography variant="h1" sx={{ lineHeight: "60px" }}>
              과
            </Typography>
          </Stack>
          <Typography
            variant="h1"
            textAlign="initial"
            sx={{ lineHeight: "60px" }}
          >
            함께하세요.
          </Typography>
          <Typography
            fontSize="4.3vw"
            textAlign="initial"
            color="#000000"
            marginTop="5%"
            marginRight="4%"
          >
            <b>1:1 맞춤형 수업</b>을 통해 학생 성적과 학습 스타일에 딱 맞는
            수업을 경험하세요.
            <br />
            티쳐스에서는 <b>100% SKY, 의치대 이상</b>의 국내 최고의 강사
            300여명이 여러분들을 기다립니다. 자체적인 검증을 통한 우수강사 추천
            시스템으로 마음에 드는 선생님을 직접 골라보세요.
            <br />
            <b>무제한 30분 무료 시범 강의</b>를 통해 학생과 딱 맞는 선생님을
            만날 수 있습니다.
          </Typography>
        </Box>
        <Swiper
          style={{ marginTop: "10%" }}
          spaceBetween={30}
          slidesPerView={1.5}
        >
          {stuList
            ?.filter((i) => i?.recruitment === true)
            ?.filter((i) => i?.excellent === true)
            .slice(0, 6)
            .map((student) => {
              return (
                <SwiperSlide
                  style={{
                    width: "61.3vw",
                    height: "70.9vw",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    window.open("https://app.teachus.co.kr");
                    document.cookie =
                      "teacher=search; path=/; max-age=5; domain=teachus.co.kr;";
                  }}
                >
                  <Box
                    borderRadius="10px"
                    bgcolor="#fff"
                    paddingY="20%"
                    paddingX="5%"
                    height="90%"
                  >
                    <Grid container justifyContent="center">
                      <TUAvatar src={student?.imageURL} size={"18vw"} />
                    </Grid>
                    <Typography sx={{ fontSize: "4.3vw", fontWeight: 700 }}>
                      {student?.name}쌤
                    </Typography>
                    <Chip
                      variant="filled"
                      sx={{
                        bgcolor: student?.subjects
                          ? [0]
                            ? student.subjects[0] === "국어"
                              ? "#D34F65"
                              : student.subjects[0] === "수학"
                              ? "#FFC234"
                              : "#2642FF"
                            : "#D34F65"
                          : "#D34F65",
                        color: "#fff",
                        width: "12vw",
                        height: "4.8vw",
                        fontSize: "2.3vw",
                        fontWeight: 600,
                      }}
                      label={
                        student?.subjects
                          ? [0]
                            ? student?.subjects[0]
                            : ""
                          : ""
                      }
                    />
                    <Typography marginTop="10%" fontSize="3.2vw">
                      {student?.university}&nbsp;
                      {student?.major}
                    </Typography>
                    <Typography marginTop="5%" fontSize="2.8vw" color="#4B57DD">
                      {student?.introductionTitle?.slice(0, 30)}
                      {student?.introductionTitle
                        ? student.introductionTitle.length > 30 && "..."
                        : null}
                    </Typography>
                  </Box>
                </SwiperSlide>
              );
            })}
          {stuList
            ?.filter((i) => i?.recruitment === true)
            ?.filter((i) => i?.excellent !== true)
            .slice(0, 9)
            .map((student) => {
              return (
                <SwiperSlide
                  style={{
                    width: "61.3vw",
                    height: "70.9vw",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    window.open("https://app.teachus.co.kr");
                    document.cookie =
                      "teacher=search; path=/; max-age=5; domain=teachus.co.kr;";
                  }}
                >
                  <Box
                    borderRadius="10px"
                    bgcolor="#fff"
                    paddingY="20%"
                    paddingX="5%"
                    height="90%"
                  >
                    <Grid container justifyContent="center">
                      <TUAvatar src={student?.imageURL} size={"18vw"} />
                    </Grid>
                    <Typography sx={{ fontSize: "4.3vw", fontWeight: 700 }}>
                      {student?.name}쌤
                    </Typography>
                    <Chip
                      variant="filled"
                      sx={{
                        bgcolor: student?.subjects
                          ? [0]
                            ? student.subjects[0] === "국어"
                              ? "#D34F65"
                              : student.subjects[0] === "수학"
                              ? "#FFC234"
                              : "#2642FF"
                            : "#D34F65"
                          : "#D34F65",
                        color: "#fff",
                        width: "12vw",
                        height: "4.8vw",
                        fontSize: "2.3vw",
                        fontWeight: 600,
                      }}
                      label={
                        student?.subjects
                          ? [0]
                            ? student?.subjects[0]
                            : ""
                          : ""
                      }
                    />
                    <Typography marginTop="10%" fontSize="3.2vw">
                      {student?.university}&nbsp;
                      {student?.major}
                    </Typography>
                    <Typography marginTop="5%" fontSize="2.8vw" color="#4B57DD">
                      {student?.introductionTitle?.slice(0, 30)}
                      {student?.introductionTitle
                        ? student.introductionTitle.length > 30 && "..."
                        : null}
                    </Typography>
                  </Box>
                </SwiperSlide>
              );
            })}
          <SwiperSlide
            style={{
              width: "61.3vw",
              height: "70.9vw",
              cursor: "pointer",
            }}
            onClick={() => {
              window.open("https://app.teachus.co.kr");
              document.cookie =
                "teacher=search; path=/; max-age=5; domain=teachus.co.kr;";
            }}
          >
            <Box
              borderRadius="10px"
              bgcolor="#fff"
              paddingY="20%"
              paddingX="5%"
              height="90%"
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: "10vw",
                    fontWeight: 700,
                  }}
                >
                  +
                </Typography>
              </Box>
              <Typography
                marginTop="25%"
                sx={{
                  fontSize: "4vw",
                  fontWeight: 600,
                }}
              >
                더 많은 선생님 보기
              </Typography>
            </Box>
          </SwiperSlide>
        </Swiper>
      </Box>
      <Box maxWidth="1447px" sx={{ bgcolor: "#fff" }} paddingTop="15%">
        <Stack marginLeft="3vw">
          <Typography variant="h1" color="#000" textAlign="initial">
            수업이 끝나도
          </Typography>
          <Stack direction="row" position="relative">
            <Typography variant="h1" color="#2642FF">
              관리는 계속
            </Typography>
            <Typography variant="h1" color="#000">
              됩니다.
            </Typography>
            <Box position="absolute" top="-63px" left="0px">
              <Image src={Small} width="15px" />
            </Box>
          </Stack>
        </Stack>
        <Grid
          container
          justifyContent="space-between"
          paddingX="3%"
          paddingTop="5%"
        >
          <Box
            width="45%"
            height="8vw"
            sx={{
              boxShadow:
                isTab === "cor" ? "none" : "0px -5px 10px rgba(0, 0, 0, 0.1)",
              bgcolor: isTab === "cor" ? "#2642FF" : "#fff",
              transition: "all ease 0.1s",
              borderTopRightRadius: 15,
              borderTopLeftRadius: 15,
              cursor: "pointer",
            }}
            onClick={() => {
              setTab("cor");
            }}
          >
            <Typography
              marginTop="2vw"
              sx={{
                color: isTab === "cor" ? "#fff" : "#9E9E9E",
                fontSize: "3vw",
                fontWeight: 600,
              }}
            >
              코디
            </Typography>
          </Box>
          <Box
            width="45%"
            height="8vw"
            sx={{
              boxShadow:
                isTab === "feed" ? "none" : "0px -5px 10px rgba(0, 0, 0, 0.1)",
              bgcolor: isTab === "feed" ? "#2642FF" : "#fff",
              transition: "all ease 0.1s",
              borderTopRightRadius: 15,
              borderTopLeftRadius: 15,
              cursor: "pointer",
            }}
            onClick={() => {
              setTab("feed");
            }}
          >
            <Typography
              marginTop="2vw"
              sx={{
                color: isTab === "feed" ? "#fff" : "#9E9E9E",
                fontSize: "3vw",
                fontWeight: 600,
              }}
            >
              피드백 리포트
            </Typography>
          </Box>
        </Grid>
        <Box height="100%" sx={{ bgcolor: "#2642FF" }}>
          {isTab === "cor" ? (
            <Box
              position="relative"
              height="221vw"
              paddingY="20%"
              paddingX="3%"
            >
              <Box position="relative">
                <Box position="absolute" top="-28px" left="0px">
                  <Image src={Small} width="20px" />
                </Box>
                <Typography
                  textAlign="initial"
                  sx={{
                    fontFamily: "GmarketB",
                    fontSize: "6.5vw",
                    lineHeight: 1.3,
                  }}
                  color="white"
                >
                  코디 선생님의 1:1 집중관리와 학생-학부모 상담을 통해
                  학습효과의 시너지를 경험하세요!
                </Typography>
              </Box>
              <Box position="absolute" width="60%" bottom="-5px" left="20%">
                <Image src={CordiImage} width="100%" />
              </Box>
              <Stack marginTop="10%">
                <Box>
                  <Stack direction="row" spacing={1}>
                    <Image src={ThumbIcon} width="9.6%" />
                    <Typography
                      textAlign="initial"
                      sx={{
                        fontSize: "5.3vw",
                        lineHeight: 1,
                        fontWeight: "bold",
                      }}
                      color="#FFC234"
                    >
                      끝난 수업도 완벽하게.
                    </Typography>
                  </Stack>
                  <Typography
                    textAlign="initial"
                    marginLeft="11%"
                    color="white"
                    marginTop="-3%"
                    sx={{ fontSize: "4.3vw", fontWeight: 400 }}
                  >
                    전담 교육 전문가 코디 선생님이 수업에 대한 리뷰와 개선
                    방안에 대해 코칭해 드립니다.
                  </Typography>
                </Box>
                <Box marginTop={isBreak ? "4.1vw" : "60px"}>
                  <Stack direction="row" spacing={1}>
                    <Image src={smallFaceIcon} width="9.6%" />
                    <Typography
                      textAlign="initial"
                      sx={{
                        fontSize: "5.3vw",
                        lineHeight: 1,
                        fontWeight: "bold",
                      }}
                      color="#FFC234"
                    >
                      학습 계획은 탄탄하게.
                    </Typography>
                  </Stack>
                  <Typography
                    textAlign="initial"
                    marginLeft="11%"
                    color="white"
                    marginTop="-3%"
                    sx={{ fontSize: "4.3vw", fontWeight: 400 }}
                  >
                    매 분기별 코디선생님과 함께 학습로드맵을 작성합니다. 작성된
                    로드맵을 바탕으로 학부모님과의 상담이 진행돼요.
                  </Typography>
                </Box>
                <Box marginTop={isBreak ? "4.1vw" : "60px"}>
                  <Stack direction="row" spacing={1}>
                    <Image src={HandIcon} width="9.6%" />

                    <Typography
                      textAlign="initial"
                      sx={{
                        fontSize: "5.3vw",
                        lineHeight: 1,
                        fontWeight: "bold",
                      }}
                      color="#FFC234"
                    >
                      공부 습관은 철저하게.
                    </Typography>
                  </Stack>
                  <Typography
                    textAlign="initial"
                    marginLeft="11%"
                    color="white"
                    marginTop="-3%"
                    sx={{ fontSize: "4.3vw", fontWeight: 400 }}
                  >
                    숙제관리, 학습습관 형성, 취약점 분석 및 보완 등의 멘토링이
                    진행돼요.
                  </Typography>
                </Box>
              </Stack>
            </Box>
          ) : (
            <Box position="relative">
              <Box position="absolute" top="10%" left="5%" marginRight="5%">
                <Box position="relative">
                  <Typography
                    textAlign="initial"
                    color="#fff"
                    sx={{
                      fontFamily: "GmarketB",
                      fontSize: "7vw",
                      lineHeight: 1.3,
                    }}
                  >
                    피드백 리포트를 통해
                    <br />
                    학습 성취도를 <br /> 확인해보세요.
                  </Typography>
                  <Typography
                    textAlign="initial"
                    color="#fff"
                    marginTop="5%"
                    sx={{
                      fontSize: "4vw",
                      fontWeight: 300,
                    }}
                  >
                    수업이 끝나면 진행된 강의 영상과 진도내용, 학습태도, 과제물
                    수행도 등을 담은 피드백 리포트를 발송해드립니다.
                    <br />
                    매주 차곡차곡 쌓이는 피드백리포트와 함께 쑥쑥 자라나는
                    학생분들의 실력을 한눈에 확인해 보세요.
                  </Typography>
                  <Box position="absolute" top="-28px" left="0px">
                    <Image src={Small} width="20px" />
                  </Box>
                </Box>
              </Box>
              <Box width="100%">
                <Image src={FeedBackImage} width="100%" />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <Box
        margin="0px auto"
        maxWidth="1447px"
        paddingX="4%"
        paddingTop="21.3%"
        height="400vw"
        position="relative"
        sx={{ bgcolor: "#fff" }}
      >
        <Box position="relative">
          <Box position="absolute" top="-28px" left="0px">
            <Image src={Small} width="20px" />
          </Box>
          <Stack direction="row">
            <Typography variant="h1" color="#2642FF">
              편리하고 효과적
            </Typography>
            <Typography variant="h1">인</Typography>
          </Stack>
          <Typography textAlign="initial" variant="h1">
            비대면 수업을 <br /> 만나보세요.
          </Typography>
        </Box>
        <Grid container justifyContent="center" marginTop="13.3vw">
          <Box width="87.7%">
            <Image src={CommuIcon} width="76%" />
            <Typography
              marginTop="10%"
              sx={{ fontSize: "5.3vw", fontWeight: 700 }}
            >
              온라인 수업 전용앱 제공
            </Typography>
            <Typography
              marginTop="3%"
              color="#222222"
              sx={{ fontSize: "4.2vw" }}
            >
              비대면 과외에 특화된 수업 전용 프로그램을 통해 최고의 학습 경험을
              제공해드려요.
            </Typography>
          </Box>
          <Box width="87.7%" marginTop="13.3vw">
            <Image src={RemindIcon} width="76%" />
            <Typography
              marginTop="10%"
              sx={{ fontSize: "5.3vw", fontWeight: 700 }}
            >
              간편하고 효과적인 복습
            </Typography>
            <Typography
              marginTop="3%"
              color="#222222"
              sx={{ fontSize: "4.2vw" }}
            >
              수업한 내용을 자동으로 녹화하여 언제든지 다시 보고 복습할 수 있어
              학습 능률이 올라갑니다.
            </Typography>
          </Box>
          <Box width="87.7%" marginTop="13.3vw">
            <Image src={DeviceIcon} width="76%" />
            <Typography
              marginTop="10%"
              sx={{ fontSize: "5.3vw", fontWeight: 700 }}
            >
              가지고 있는 기기 그대로 !
            </Typography>
            <Typography
              marginTop="3%"
              color="#222222"
              sx={{ fontSize: "4.2vw" }}
            >
              아이패드, 갤럭시 탭 등 태블릿은 물론, 노트북, PC에서도 그대로
              수업을 들으실 수 있습니다.
            </Typography>
          </Box>
        </Grid>

        <Button
          variant="contained"
          sx={{
            position: "absolute",
            bottom: "3%",
            right: "26%",
            bgcolor: "#2642FF",
            color: "#fff",
            fontWeight: 600,
            fontSize: "3.5vw",
            height: "10vw",
            width: "45vw",
            borderRadius: 10,
            ":hover": {
              bgcolor: "#1b2eb2",
            },
          }}
          onClick={() => {
            window.open(
              "https://info.teachus.co.kr/594f72eee0a74395857fbcad49edf080"
            );
          }}
        >
          전용 앱 자세히 보기
        </Button>
      </Box>
      <Box
        margin="0px auto"
        maxWidth="1447px"
        paddingLeft="4%"
        height="169vw"
        paddingTop="21.3%"
        sx={{ bgcolor: "#F8F8F8" }}
      >
        <Box position="relative">
          <Box position="absolute" top="-28px" left="0px">
            <Image src={Small} width="20px" />
          </Box>
          <Typography
            variant="h1"
            textAlign="initial"
            sx={{ lineHeight: "60px" }}
          >
            가장 합리적인
          </Typography>
          <Typography
            textAlign="initial"
            variant="h1"
            sx={{ lineHeight: "60px" }}
          >
            가격으로 제공합니다.
          </Typography>
          <Typography
            marginTop="5%"
            textAlign="initial"
            color="#000000"
            sx={{ fontSize: "4.3vw" }}
          >
            티쳐스는 수수료를 최소화하여 <br /> 학생들의 수업비 부담을 덜고자
            합니다.
            <br />
            선생님 별로 다양한 가격대의 수업료를 통해 선택의 폭을 넓혔습니다.
            <br />
            타사와 비교해 보시면 현명한 선택임을 확인해 보실 수 있습니다.
          </Typography>
          <Box marginTop="10.7vw">
            <Image src={PriceImage} width="90.9vw" />
          </Box>
          <Typography
            marginTop="3.5vw"
            color="#C4C4C4"
            sx={{ fontSize: "4.3vw" }}
          >
            *주 2회 60분, 한 달 수업료 기준, 강사별 상이
          </Typography>
        </Box>
      </Box>
      <Box
        margin="0px auto"
        paddingX="4%"
        height="auto"
        paddingTop="21.3%"
        paddingBottom="60px"
        sx={{ bgcolor: "#FFF" }}
      >
        <Stack direction="row" position="relative">
          <Box position="absolute" top="-38px" left="0px">
            <Image src={Small} width="20px" />
          </Box>
          <Typography variant="h1" color="#2642FF" sx={{ lineHeight: "60px" }}>
            생생한 수업 후기
          </Typography>
          <Typography variant="h1" sx={{ lineHeight: "60px" }}>
            를
          </Typography>
        </Stack>
        <Typography
          textAlign="initial"
          variant="h1"
          sx={{ lineHeight: "60px" }}
        >
          확인해 보세요!
        </Typography>
        <Box position="relative" paddingBottom="56.25%" marginTop="24px">
          <iframe
            style={{ position: "absolute", top: "0", left: "0" }}
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/llrUktd4s3A"
          ></iframe>
        </Box>
        <Typography
          marginTop="2%"
          textAlign="center"
          color="#C4C4C4"
          sx={{ fontSize: "3.8vw" }}
        >
          티쳐스 이용 후기 영상
        </Typography>
        <Swiper
          style={{ marginTop: "10%" }}
          spaceBetween={30}
          slidesPerView={1.3}
        >
          <SwiperSlide
            style={{
              width: "68vw",
              height: "88.7vw",
            }}
          >
            <Box
              height="75%"
              borderRadius="10px"
              bgcolor="rgba(255, 194, 52, 0.1)"
              padding="8.8vw 7.2vw"
              position="relative"
              sx={{ whiteSpace: "normal" }}
            >
              <Typography
                color="#333333"
                sx={{ fontSize: "4.8vw" }}
                textAlign="initial"
              >
                선생님께서 써주신 필기도 저장되고, 수업 영상도 몇번이고 돌려볼
                수 있어서 편하네용.. 복습할 때 확실히 편해요.
              </Typography>
              <Stack
                direction="row"
                spacing={1}
                position="absolute"
                left="10%"
                bottom="10%"
                alignItems="center"
              >
                <Chip
                  variant="filled"
                  sx={{
                    bgcolor: "#FFB800",
                    color: "#2642FF",
                    height: "5.3vw",
                    fontSize: "3.2vw",
                    fontWeight: 600,
                  }}
                  label={"학생"}
                />
                <Typography sx={{ fontSize: "4.3vw" }}>손*희</Typography>
              </Stack>
            </Box>
          </SwiperSlide>
          <SwiperSlide
            style={{
              width: "68vw",
              height: "88.7vw",
            }}
          >
            <Box
              height="75%"
              borderRadius="10px"
              bgcolor="rgba(255, 194, 52, 0.1)"
              padding="8.8vw 7.2vw"
              position="relative"
              sx={{ whiteSpace: "normal" }}
            >
              <Typography
                color="#333333"
                sx={{ fontSize: "4.8vw" }}
                textAlign="initial"
              >
                코디쌤이 있어서 도움이 정말 많이 돼요! 학습관련 질문 뿐만 아니라
                공부관련 고민상담도 해주셔서 항상 감사해요 ㅜㅜㅜ 귀찮게 해도
                항상 친절하게 받아주시는 코디쌤...사랑합니다...♡
              </Typography>
              <Stack
                direction="row"
                spacing={1}
                position="absolute"
                left="10%"
                bottom="10%"
                alignItems="center"
              >
                <Chip
                  variant="filled"
                  sx={{
                    bgcolor: "#FFB800",
                    color: "#2642FF",
                    height: "5.3vw",
                    fontSize: "3.2vw",
                    fontWeight: 600,
                  }}
                  label={"학생"}
                />
                <Typography sx={{ fontSize: "4.3vw" }}> 김*진</Typography>
              </Stack>
            </Box>
          </SwiperSlide>
          <SwiperSlide
            style={{
              width: "68vw",
              height: "88.7vw",
            }}
          >
            <Box
              height="75%"
              borderRadius="10px"
              bgcolor="rgba(0, 243, 128, 0.11)"
              padding="8.8vw 7.2vw"
              position="relative"
              sx={{ whiteSpace: "normal" }}
            >
              <Typography
                color="#333333"
                sx={{ fontSize: "4.8vw" }}
                textAlign="initial"
              >
                요즘 저희 애가 티쳐스로 공부 열심히 하고있어요~ 피드백 리포트를
                정기적으로 제공해줘서 직접 수업에 참여하지 않더라도 우리 아이
                진도나 학습 태도를 손쉽게 확인할 수 있는게 좋은것같아요^^
              </Typography>
              <Stack
                direction="row"
                spacing={1}
                position="absolute"
                left="10%"
                bottom="10%"
                alignItems="center"
              >
                <Chip
                  variant="filled"
                  sx={{
                    bgcolor: "#00F380",
                    color: "#fff",
                    height: "5.3vw",
                    fontSize: "3.2vw",
                    fontWeight: 600,
                  }}
                  label={"학부모"}
                />
                <Typography sx={{ fontSize: "4.3vw" }}> 김*영</Typography>
              </Stack>
            </Box>
          </SwiperSlide>
          <SwiperSlide
            style={{
              width: "68vw",
              height: "88.7vw",
            }}
          >
            <Box
              height="75%"
              borderRadius="10px"
              bgcolor="rgba(255, 194, 52, 0.1)"
              padding="8.8vw 7.2vw"
              position="relative"
              sx={{ whiteSpace: "normal" }}
            >
              <Typography
                color="#333333"
                sx={{ fontSize: "4.8vw" }}
                textAlign="initial"
              >
                코디쌤이 있어서 도움이 정말 많이 돼요! 학습관련 질문 뿐만 아니라
                공부관련 고민상담도 해주셔서 항상 감사해요 ㅜㅜㅜ
              </Typography>
              <Stack
                direction="row"
                spacing={1}
                position="absolute"
                left="10%"
                bottom="10%"
                alignItems="center"
              >
                <Chip
                  variant="filled"
                  sx={{
                    bgcolor: "#FFB800",
                    color: "#2642FF",
                    height: "5.3vw",
                    fontSize: "3.2vw",
                    fontWeight: 600,
                  }}
                  label={"학생"}
                />
                <Typography sx={{ fontSize: "4.3vw" }}> 이*영</Typography>
              </Stack>
            </Box>
          </SwiperSlide>
          <SwiperSlide
            style={{
              width: "68vw",
              height: "88.7vw",
            }}
          >
            <Box
              height="75%"
              borderRadius="10px"
              bgcolor="rgba(255, 194, 52, 0.1)"
              padding="8.8vw 7.2vw"
              position="relative"
              sx={{ whiteSpace: "normal" }}
            >
              <Typography
                color="#333333"
                sx={{ fontSize: "4.8vw" }}
                textAlign="initial"
              >
                딴데에서 온라인과외 듣다가 선생님이 너무 저랑 안맞아서 티쳐스로
                옮겼는데 여기 쌤들은 다 너무 좋으셔용... 모르는 문제도 차근히
                알려주시고 친근하게 해주셔서 수업이 넘 재미있어용!!
              </Typography>
              <Stack
                direction="row"
                spacing={1}
                position="absolute"
                left="10%"
                bottom="10%"
                alignItems="center"
              >
                <Chip
                  variant="filled"
                  sx={{
                    bgcolor: "#FFB800",
                    color: "#2642FF",
                    height: "5.3vw",
                    fontSize: "3.2vw",
                    fontWeight: 600,
                  }}
                  label={"학생"}
                />
                <Typography sx={{ fontSize: "4.3vw" }}> 김*희</Typography>
              </Stack>
            </Box>
          </SwiperSlide>
        </Swiper>
        <Typography
          marginTop="2%"
          textAlign="initial"
          color="#C4C4C4"
          sx={{ fontSize: "3.8vw" }}
        >
          본 후기는 티쳐스 알파 테스트 버전 이용자 후기입니다.
        </Typography>
      </Box>
    </div>
  );
};

export default StudentMobHome;
