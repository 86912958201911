import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';


if(navigator.userAgent.indexOf("Trident") > 0){ 
  alert("티쳐스는 Microsoft Edge, Chrome 브라우저에 최적화 되어있습니다. " + "원활한 사용을 원하시면 Microsoft Edge, Chrome 브라우저를 권장합니다. 확인버튼을 누르면 Edge브라우저로 자동으로 이동됩니다.");
  window.location.href = 'microsoft-edge:https://www.teachus.co.kr'
} 
else if(/MSIE \d |Trident.*rv:/.test(navigator.userAgent)){
  alert("티쳐스는 Microsoft Edge, Chrome 브라우저에 최적화 되어있습니다. " + "원활한 사용을 원하시면 Microsoft Edge, Chrome 브라우저를 권장합니다. 확인버튼을 누르면 Edge브라우저로 자동으로 이동됩니다.");
  window.location.href = 'microsoft-edge:https://www.teachus.co.kr'
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
